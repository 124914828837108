import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import FormHelperText from '@mui/material/FormHelperText';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GoogleLogin from 'react-google-login';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
import { GOOGLE_CLIENT_ID } from '../../config';
import visionarLogo from '../../images/visionar_logo_2021_white.svg';
import backgroundImage from '../../images/background.png';
import { useLoginMutation, useSignInWithGoogleMutation } from '../../services/visionarRTK';
import { validateEmail } from '../../utils';
import Copyright from '../copyright';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: 'rgb(28,132,255)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '100vh',
    paddingTop: theme.spacing(10),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    backgroundColor: 'white',
    marginTop: theme.spacing(6),
    padding: theme.spacing(8),
    boxShadow: '0px 4px 24px rgb(0 0 0 / 10%)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      margin: theme.spacing(1),
      padding: theme.spacing(4),
      width: 'auto',
      boxShadow: 'none',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBar: {
    boxShadow: 'none',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  logo: {
    width: '187px',
    maxWidth: '187px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      maxWidth: '150px',
    },
  },
  signUpButton: {
    marginLeft: theme.spacing(2),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  wrapper: {
    paddingBottom: '100px',
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    height: '50px',
    maxWidth: '100%',
    color: 'rgba(255, 255, 255, 0.7)',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.text.secondary,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  googleButton: {
    width: '100%',
    marginTop: theme.spacing(6),
    height: '50px',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 4px 0px !important',
    fontSize: '1rem !important',
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.50)',
    '&::before': {
      content: '""',
      flex: '1',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    },
    '&::after': {
      content: '""',
      flex: '1',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    },
    '&:not(:empty)::before': {
      marginRight: '.25em',
    },
    '&:not(:empty)::after': {
      marginLeft: '.25em',
    },
  },
  errorMessageHelperText: {
    color: theme.palette.error.main,
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotPasswordLink: {
    color: theme.palette.primary.main,
  },
  googleErrorMessage: {
    marginTop: theme.spacing(1),
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const [login, { isLoading }] = useLoginMutation();
  const [signInWithGoogle, signInWithGoogleResult] = useSignInWithGoogleMutation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValidation, setEmailValidation] = useState(null);
  const [passwordValidation, setPasswordValidation] = useState(null);
  const [error, setError] = useState(null);
  const [googleError, setGoogleError] = useState(null);

  const handleGoogleSignIn = async (googleData) => {
    try {
      const user = await signInWithGoogle({ token: googleData.tokenId }).unwrap();
      sessionStorage.setItem('access-token', user.token);
      if (!user.privacyAccepted) {
        history.replace('/signup#privacy');
      } else if (!user.subscriptionStatus
        || user.subscriptionStatus === 'canceled'
        || user.subscriptionStatus === 'unpaid'
        || user.subscriptionStatus === 'past_due'
        || user.subscriptionStatus === 'incomplete'
        || user.subscriptionStatus === 'incomplete_expired'
        || (user.subscriptionStatus === 'active' && user.currentSubscriptionPeriodEnd < new Date())
        || (user.subscriptionStatus === 'trialing' && user.currentSubscriptionPeriodEnd < new Date())) {
        history.replace('/signup?plan=returning#welcome');
      } else {
        history.replace(from);
      }
    } catch (err) {
      switch (err.status) {
        case 401:
          setGoogleError('google_sign_up_required');
          break;
        default:
          setGoogleError('unknown_error');
      }
    }
  };

  const validateForm = () => {
    let errorFound = false;
    if (!email || !validateEmail(email)) {
      setEmailValidation('Please enter a correct email address.');
      errorFound = true;
    }

    if (!password) {
      setPasswordValidation('Please enter a password.');
      errorFound = true;
    }

    return !errorFound;
  };

  const onFormSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!validateForm()) return;
      const user = await login({
        email,
        password,
      }).unwrap();
      sessionStorage.setItem('access-token', user.token);

      if (!user.privacyAccepted) {
        history.replace('/signup#privacy');
      } else if (!user.subscriptionStatus
        || user.subscriptionStatus === 'canceled'
        || user.subscriptionStatus === 'unpaid'
        || user.subscriptionStatus === 'past_due'
        || user.subscriptionStatus === 'incomplete'
        || user.subscriptionStatus === 'incomplete_expired'
        || (user.subscriptionStatus === 'active' && user.currentSubscriptionPeriodEnd < new Date())
        || (user.subscriptionStatus === 'trialing' && user.currentSubscriptionPeriodEnd < new Date())) {
        history.replace('/signup?plan=returning#welcome');
      } else {
        history.replace(from);
      }
    } catch (err) {
      switch (err.status) {
        case 401:
          setError('wrong_email_password');
          break;
        default:
          setError('unknown_error');
      }
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Toolbar>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <img src={visionarLogo} alt="logo" className={classes.logo} />
            </Grid>
            {
              matches
                ? (
                  <Grid item>
                    <Typography variant="body1" display="inline">Don&apos;t have an account?</Typography>
                    <Button
                      color="inherit"
                      className={classes.signUpButton}
                      component={RouterLink}
                      variant="outlined"
                      to="signup#account-setup"
                    >
                      SIGN UP NOW
                    </Button>
                  </Grid>
                )
                : (
                  <Button
                    color="inherit"
                    className={classes.signUpButton}
                    component={RouterLink}
                    size="small"
                    variant="outlined"
                    to="signup#account-setup"
                  >
                    SIGN UP
                  </Button>
                )
            }
          </Grid>
        </Toolbar>
      </AppBar>

      <div className={classes.wrapper}>
        <Container component="main" maxWidth="xs" className={classes.container}>
          <CssBaseline />

          <div className={classes.paper}>
            {
              matches
                ? (
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                )
                : null
            }

            <Typography component="h1" variant="h5">Welcome back!</Typography>
            <GoogleLogin
              className={classes.googleButton}
              clientId={GOOGLE_CLIENT_ID}
              buttonText="Sign in with Google"
              onSuccess={handleGoogleSignIn}
              onFailure={handleGoogleSignIn}
              disabled={isLoading || signInWithGoogleResult.isLoading}
              cookiePolicy="single_host_origin"
            />
            {
              googleError
                ? (
                  <Alert icon={false} severity="error" className={classes.googleErrorMessage}>
                    {
                      googleError === 'google_sign_up_required' ? (
                        <span>
                          Could not find your account. Please
                          <RouterLink to="/signup#account-setup"> sign up</RouterLink>
                          {' '}
                          or use another email address.
                        </span>
                      ) : (
                        <span>
                          An authentication exception occurred. Please try again or
                          <a href="mailto:support@visionar.com"> contact support</a>
                          .
                        </span>
                      )
                    }
                  </Alert>
                ) : null
            }
            <div className={classes.separator}>or</div>
            <form className={classes.form} noValidate onSubmit={async (e) => onFormSubmit(e)}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                error={emailValidation != null}
                helperText={emailValidation}
                name="email"
                value={email}
                disabled={isLoading || signInWithGoogleResult.isLoading}
                onChange={(e) => {
                  setEmailValidation(null);
                  setEmail(e.target.value);
                }}
                autoComplete="email"
                autoFocus
                aria-describedby="email-helper-text"
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                value={password}
                error={passwordValidation != null}
                label="Password"
                type="password"
                id="password"
                helperText={passwordValidation}
                disabled={isLoading || signInWithGoogleResult.isLoading}
                onChange={(e) => {
                  setPasswordValidation(null);
                  setPassword(e.target.value);
                }}
                autoComplete="current-password"
              />
              {
                error
                  ? (
                    <Alert icon={false} severity="error">
                      {
                        error === 'wrong_email_password' ? <span>Incorrect email or password.</span> : (
                          <span>
                            An authentication exception occurred. Please try again or
                            <a href="mailto:support@visionar.com"> contact support</a>
                            .
                          </span>
                        )
                      }
                    </Alert>
                  ) : null
              }
              <FormHelperText id="password-helper-text" style={{ textAlign: 'right' }}>
                <RouterLink to="forgotPassword" className={classes.forgotPasswordLink}>Forgot password?</RouterLink>
              </FormHelperText>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading || signInWithGoogleResult.isLoading}
                className={classes.button}
              >
                {isLoading ? <CircularProgress size={24} /> : <span>SIGN IN</span>}
              </Button>
            </form>
          </div>

        </Container>
      </div>

      <Container xs={12} className={classes.footer}>
        <Copyright />
      </Container>
    </div>
  );
};

export default LoginPage;
