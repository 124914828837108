import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import DialogHeader from './dialog-header';
import { useCreateProjectMutation } from '../services/visionarRTK';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingBottom: '0',
  },
  dialogContent: {
    paddingBottom: '24px',
  },
  errorAlert: {
    marginTop: theme.spacing(2),
  },
}));

const SetupWizard = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const [createProject, { isLoading }] = useCreateProjectMutation();
  const [name, setName] = useState('');
  const [error, setError] = useState(null);

  const onFormSubmit = async (event) => {
    try {
      event.preventDefault();
      await createProject({name}).unwrap();
      setName('');
      onClose();
    } catch (err) {
      setError('unknown_error');
    }
  };

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={isLoading}
      PaperProps={{
        style: { minWidth: '500px' },
      }}
    >
      <form onSubmit={onFormSubmit}>
        <DialogHeader />
        <DialogTitle className={classes.dialogTitle}>What&apos;s the name of the project?</DialogTitle>
        <DialogContent className={classes.dialogContent}>

          <TextField
            autoFocus
            fullWidth
            disabled={isLoading}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your project name"
            variant="standard"
            margin="normal"
          />


          {error ? (
            <Alert className={classes.errorAlert} icon={false} severity="error">
              <span>
                An exception occurred. Please try again or
                <a href="mailto:support@visionar.com"> contact support</a>
                .
              </span>
            </Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={name.length <= 0}>
            {isLoading ? <CircularProgress size={24} /> : <span>Create</span>}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetupWizard;

SetupWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
