import Immutable, { Immut } from 'immutable';
import {
    TOGGLE_PREVIEW_MODE, SELECT_TAB, SET_ERROR, LOAD_USER_SUCCESS, LOAD_USER_FAILURE,
    TOGGLE_SHOW_TUTORIAL, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE
} from "../actions/app";

const initialState = Immutable.fromJS({
    previewMode: false,
    showTutorial: false,
    error: null,
    user: null,
});

const settingReducer = (state: Immut = initialState, action: { type: string, payload: any }) => {
    switch (action.type) {
        case TOGGLE_PREVIEW_MODE: {
            return state.set('previewMode', Immutable.fromJS(!state.get('previewMode')));
        }
        case SELECT_TAB: {
            return state.set('activeTab', action.payload);
        }
        case SET_ERROR: {
            return state.set('error', action.payload);
        }
        case LOAD_USER_SUCCESS: {
            return state.set('user', action.payload);
        }
        case LOAD_USER_FAILURE: {
            return state.set('error', '404 User nicht gefunden!');
        }
        case TOGGLE_SHOW_TUTORIAL: {
            return state.set('showTutorial', Immutable.fromJS(!state.get('showTutorial')));
        }
        case UPDATE_USER_SUCCESS: {
            return state.set('user', action.payload);
        }
        case UPDATE_USER_FAILURE: {
            return state.set('error', '404 User nicht gefunden!');
        }
        default:
            return state;
    }
};

export default settingReducer;