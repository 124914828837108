import { connect } from 'react-redux';

import App from '../App';
import { loadUserSuccess } from '../actions/app';
import { getCurrentUser } from '../selectors/setting';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadUserSuccess: (user) => { dispatch(loadUserSuccess(user)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
