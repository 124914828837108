export function getSelectedProject(state) {
  if (!state.project) {
    return undefined;
  }

  const stateAsJson = state.project.toJS();
  if (!stateAsJson.selectedProject) {
    return undefined;
  }

  return stateAsJson.projects.find(((project) => project.id === stateAsJson.selectedProject));
}

export function getProjects(state) {
  if (!state.project) {
    return undefined;
  }

  return state.project.toJS().projects;
}

export function getIsDemoOnly(state) {
  if (!state.project) {
    return false;
  }

  const stateAsJson = state.project.toJS();
  if (!stateAsJson.isDemoOnly) {
    return false;
  }

  return stateAsJson.isDemoOnly;
}

export function getHasSingleNewProject(state) {
  if (!state.project) {
    return false;
  }

  const stateAsJson = state.project.toJS();
  if (!stateAsJson.hasSingleNewProject) {
    return false;
  }

  return stateAsJson.hasSingleNewProject;
}

export function getIsVuforiaKeyVerifyRunning(state) {
  if (!state.project) {
    return false;
  }

  const stateAsJson = state.project.toJS();
  if (!stateAsJson.isVuforiaKeyVerifyRunning) {
    return false;
  }

  return stateAsJson.isVuforiaKeyVerifyRunning;
}

export function getVuforiaKeysVerified(state) {
  if (!state.project) {
    return false;
  }

  const stateAsJson = state.project.toJS();
  if (!stateAsJson.vuforiaKeysVerified) {
    return false;
  }

  return stateAsJson.vuforiaKeysVerified;
}

export function getIsSecretKeyVerifyRunning(state) {
  if (!state.project) {
    return false;
  }

  const stateAsJson = state.project.toJS();
  return stateAsJson.secretKeyVerifyRunning;
}

export function getIsSecretKeyVerified(state) {
  if (!state.project) {
    return false;
  }

  const stateAsJson = state.project.toJS();
  return stateAsJson.secretKeyVerified;
}
