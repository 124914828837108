import React from 'react';
import { grey } from '@mui/material/colors';
import appStore from '../images/appstore.png';
import playStore from '../images/playstore.png';

const QRCode = require('qrcode.react');

const styles = {
  instructionsContainer: {
    height: '100%',
    overflow: 'auto',
    display: 'block',
  },
  instructionsContainerHidden: {
    display: 'none',
  },
  checkListItem: {
    borderRadius: '50%',
    textAlign: 'center',
    border: '2px solid var(--secondaryColor)',
    width: '32px',
    float: 'left',
    color: 'var(--secondaryColor)',
  },
  text: {
    marginLeft: '40px',
    lineHeight: '2',
    color: 'var(--primaryText)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
  },
  qrCode: {
    textAlign: 'center',
    marginTop: '16px',
  },
  headerView: {
    height: '48px',
    display: 'flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    boxShadow: '0px 1px 0px 0px var(--dividerColor)',
    paddingLeft: '16px',
    color: 'var(--primaryText)',
    backgroundColor: '#F5F5F5',
    borderBottom: `1px solid ${grey[200]}`,
    borderTop: `1px solid ${grey[200]}`,
  },
};

class PreviewInstructions extends React.Component {
  headerView = () => (
    <div style={styles.headerView}>
      <h6 style={{ fontWeight: 'normal', marginBottom: '0px' }}>How you test your app</h6>
    </div>
  );

  render() {
    return (
      <div style={this.props.previewMode ? styles.instructionsContainer : styles.instructionsContainerHidden}>
        {
                    this.props.previewMode
                      ? (
                        <div>
                          <div style={{ padding: '16px' }}>
                            <h5 style={styles.checkListItem}>1</h5>
                            <div style={styles.text}>
                              Download the visionar App
                              <div style={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingRight: '40px',
                              }}
                              >
                                <a style={{ width: '140px', marginTop: '16px' }} target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/at/app/v-view/id894538059?mt=8">
                                  <img style={styles.image} alt="Download from App Store" src={appStore} />
                                </a>
                                <a style={{ width: '140px', marginTop: '16px' }} target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.visionar.sample">
                                  <img style={styles.image} alt="Download from Play Store" src={playStore} />
                                </a>
                              </div>
                            </div>
                            <br />
                            <h5 style={styles.checkListItem}>2</h5>
                            <div style={styles.text}>
                              Sign in with your visionar code,
                              {' '}
                              <br />
                              {' '}
                              or scan this QR Code.
                            </div>
                            {
                              this.props.selectedProject && this.props.selectedProject.accessCode
                                ? (
                                  <div style={styles.qrCode}>
                                    <QRCode style={{ width: '116px', height: '116px' }} value={this.props.selectedProject.accessCode} />
                                    <div>
                                      <b>{this.props.selectedProject.accessCode}</b>
                                    </div>
                                  </div>
                                )
                                : null
                            }

                            <br />
                            <h5 style={styles.checkListItem}>3</h5>
                            <div style={styles.text}>
                              Scan your target image after
                              {' '}
                              <br />
                              {' '}
                              successful processing and
                              {' '}
                              <br />
                              make sure, that they work as
                              {' '}
                              <br />
                              expected.
                            </div>
                          </div>
                        </div>
                      )
                      : <div />
                }
      </div>
    );
  }
}

export default PreviewInstructions;
