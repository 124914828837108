import FormData from 'form-data';
import { saveAs } from 'file-saver/FileSaver';
import { BACKEND_SERVER } from '../config';
import { getProjectKey, getToken } from '../auth';
import * as util from './visionar.util';

class VisionarService {
  async loginUser(email, password) {
    const url = `${BACKEND_SERVER}/users/login`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (!response.ok) {
      throw new Error(`VisionarService ${this.loginUser.name} failed.`);
    }

    const data = await response.json();
    if (localStorage.getItem('isFirstLogin') !== 'false') {
      localStorage.setItem('isFirstLogin', true.toString());
    }

    return {
      id: data.id,
      displayName: data.displayName,
      email: data.email,
      token: data.token,
      neverShowTips: data.neverShowTips,
    };
  }

  async register(email, password) {
    const url = `${BACKEND_SERVER}/users/register`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    if (localStorage.getItem('isFirstLogin') !== 'false') {
      localStorage.setItem('isFirstLogin', true.toString());
    }

    return {
      id: data.id,
      displayName: data.displayName,
      email: data.email,
      token: data.token,
      neverShowTips: data.neverShowTips,
    };
  }

  async googleSignUp(tokenId) {
    const url = `${BACKEND_SERVER}/auth/google`;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        token: tokenId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    const data = await response.json();
    // if (localStorage.getItem('isFirstLogin') !== 'false') {
    //   localStorage.setItem('isFirstLogin', true.toString());
    // }
    
    return {
      id: data.id,
      displayName: data.displayName,
      email: data.email,
      token: data.token,
      neverShowTips: data.neverShowTips,
      picture: data.picture,
      privacyAccepted: data.privacyAccepted,
    };
  }

  async getMe() {
    const url = `${BACKEND_SERVER}/me`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-access-token': getToken(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.getMe.name);
    }

    const data = await response.json();
    return {
      id: data.id,
      displayName: data.displayName,
      email: data.email,
      token: data.token,
      neverShowTips: data.neverShowTips,
    };
  }

  async getUserProjects(userId) {
    const url = `${BACKEND_SERVER}/me/projects`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-access-token': getToken(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.getUserProjects.name);
    }

    const data = await response.json();
    return data.map((project) => (
      util.mapProject(project)
    ));
  }

  async getProject(projectId) {
    const url = `${BACKEND_SERVER}/projects/${projectId}`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-access-token': getToken(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.getProject.name);
    }

    const data = await response.json();
    return util.mapProject(data);
  }

  async updateProject(project) {
    const url = `${BACKEND_SERVER}/projects/${project.id}`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'PUT',
      body: JSON.stringify(project),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': getToken(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.updateProject.name);
    }

    const data = await response.json();
    return data;
  }

  async getProjectTargets(projectId) {
    const url = `${BACKEND_SERVER}/targets/project/${projectId}`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-access-token': getToken(),
        'project-key': getProjectKey(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.getProjectTargets.name);
    }

    const data = await response.json();
    return util.mapTargetResponse(data);
  }

  async validateUpload(size, count, projectId) {
    const response = await fetch(`${BACKEND_SERVER}/projects/validateupload`, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({ size, count, projectId }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': getToken(),
      },
    });

    const uploadAllowed = await response.json();
    return uploadAllowed;
  }

  async uploadTarget(image, projectId, onProgressCallBack) {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(`${BACKEND_SERVER}/projects/validateupload`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ size: image.size, projectId, count: 1 }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': getToken(),
          'project-key': getProjectKey(),
        },
      });

      const uploadAllowed = await response.json();

      if (!uploadAllowed) {
        reject({
          status: 400,
          statusText: 'storage_limit',
        });
      } else {
        const formData = new FormData();
        formData.append('projectId', projectId);
        formData.append('image', image);
        formData.append('name', image.name);

        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener('progress', (e) => {
          let progress = 0;
          if (e.total !== 0) {
            progress = parseInt((e.loaded / e.total) * 100, 10);
            onProgressCallBack(progress);
          }
        }, true);
        xhr.onerror = function (e) {
          xhr.abort();
          if (this.status === 401) {
            window.location.href = '/';
          }
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            const data = JSON.parse(this.response);
            resolve(util.mapTarget(data));
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          }
        };

        // your url upload
        xhr.open('post', `${BACKEND_SERVER}/targets`, true);
        xhr.setRequestHeader('x-access-token', getToken());
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('project-key', getProjectKey());

        xhr.send(formData);
      }
    });
  }

  async getTarget(targetId) {
    const url = `${BACKEND_SERVER}/targets/${targetId}`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'x-access-token': getToken(),
        'project-key': getProjectKey(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.getTarget.name);
    }

    const data = await response.json();
    return data;
  }

  async deleteTarget(targetId) {
    const url = `${BACKEND_SERVER}/targets/${targetId}`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'x-access-token': getToken(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.deleteTarget.name);
    }

    const data = await response.json();
    return data;
  }

  async updateTarget(target) {
    const targetId = target.id || target.get('id');
    const url = `${BACKEND_SERVER}/targets/${targetId}`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'PUT',
      body: JSON.stringify(target),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': getToken(),
        'project-key': getProjectKey(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.updateTarget.name);
    }

    const data = await response.json();
    return util.mapTarget(data);
  }

  async uploadContent(targetId, itemId, file, onProgressCallBack) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('itemId', itemId);

      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener('progress', (e) => {
        let progress = 0;
        if (e.total !== 0) {
          progress = parseInt((e.loaded / e.total) * 100, 10);
          onProgressCallBack(progress);
        }
      }, true);
      xhr.onerror = function (e) {
        xhr.abort();
        if (this.status === 401) {
          window.location.href = '/';
        }
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          const data = JSON.parse(this.response);
          resolve(util.mapTarget(data));
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      };

      // your url upload
      xhr.open('post', `${BACKEND_SERVER}/targets/${targetId}/uploadContent`, true);
      xhr.setRequestHeader('x-access-token', getToken());
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('project-key', getProjectKey());

      xhr.send(formData);
    });
  }

  handleHttpError(response, name) {
    if (response.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/';
    }
    throw new Error(`VisionarService ${name} failed, HTTP status ${response.status}`);
  }

  async getProjectKeyFile(project, productKey, recreate = false) {
    const url = !recreate ? `${BACKEND_SERVER}/projects/${project.id}/keyfile` : `${BACKEND_SERVER}/projects/${project.id}/recreatekeyfile`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({ projectId: project.id, productKey, userId: project.user }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': getToken(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.getProject.name);
    }

    await response.json();
    await fetch(`${BACKEND_SERVER}/projects/${project.id}/downloadfile`, {
      headers: {
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob',
    }).then((response) => response.blob())
      .then((blob) => saveAs(blob, 'Visionar Secret Key.pdf'));
  }

  async verifyVuforiaKeys(project) {
    const url = `${BACKEND_SERVER}/projects/${project.id}/verifyconnection`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({ accessKey: project.accessKey, secretKey: project.secretKey }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': getToken(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.getProject.name);
    }

    const data = await response.json();
    return data;
  }

  // async getUserByWpId(id) {
  //   const url = `${BACKEND_SERVER}/users/${id}`;
  //   const response = await fetch(url, {
  //     credentials: "include",
  //     method: 'GET',
  //     headers: {
  //       Accept: 'application/json',
  //       'x-access-token': getToken(),
  //     },
  //   });

  //   if (!response.ok) {
  //     this.handleHttpError(response, this.getTarget.name);
  //   }

  //   const data = await response.json();
  //   return util.mapUser(data);
  // }

  async updateUser(user) {
    const url = `${BACKEND_SERVER}/users/${user.id}`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'PUT',
      body: JSON.stringify(user),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': getToken(),
      },
    });

    if (!response.ok) {
      this.handleHttpError(response, this.updateUser.name);
    }

    const data = await response.json();
    return data;
  }

  async createStripeCheckoutSession(email, priceId) {
    const url = `${BACKEND_SERVER}/create-checkout-session`;
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        priceId,
      }),
    });

    if (!response.ok) {
      this.handleHttpError(response, this.createStripeCheckoutSession.name);
    }

    const data = await response.json();
    return {
      sessionId: data.sessionId,
    };
  }

  // async verifySecretKey(userId, secret) {
  //   const url = `${BACKEND_SERVER}/projects/verifysecret`;
  //   const response = await fetch(url, {
  //     credentials: "include",
  //     method: 'POST',
  //     body: JSON.stringify({userId: userId, secretKey: secret}),
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       'x-access-token': getToken(),
  //     },
  //   });

  //   if (!response.ok) {
  //     this.handleHttpError(response, this.verifySecretKey.name);
  //   }

  //   const data = await response.json();
  //   return data;
  // }

  // async setAppKey(projectId, appKey) {
  //   const url = `${BACKEND_SERVER}/projects/${projectId}/appKey`;
  //   const response = await fetch(url, {
  //     credentials: "include",
  //     method: 'POST',
  //     body: JSON.stringify({appKey: appKey}),
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       'x-access-token': getToken(),
  //     },
  //   });

  //   if (!response.ok) {
  //     this.handleHttpError(response, this.setAppKey.name);
  //   }

  //   await response;
  // }
}

export default new VisionarService();
