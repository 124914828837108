import React from 'react';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOffIcon from '@mui/icons-material/GridOff';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import IconButton from '@mui/material/IconButton';

type CanvasToolbarProps = {
  isZoomedOut: boolean,
  isGridOn: boolean,
  onZoomButtonClicked(event: React.MouseEvent<HTMLButtonElement>): void,
  onGridButtonClicked(event: React.MouseEvent<HTMLButtonElement>): void,
};

function CanvasToolbar({ isZoomedOut, isGridOn, onGridButtonClicked, onZoomButtonClicked }: CanvasToolbarProps) {
  const styles = {
    container: {
      width: '40px',
      backgroundColor: grey[300],
      borderRadius: '16px',
      paddingTop: '24px',
      paddingBottom: '24px',
      position: 'fixed', 
      bottom: '32px',
      right: '32px'
    },
  };

  return (
    <Box sx={styles.container}>
      <IconButton aria-label="zoom" sx={{ color: 'text.primary' }} onClick={onZoomButtonClicked}>
        {isZoomedOut ? <ZoomOutMapIcon /> : <ZoomInMapIcon />}
      </IconButton>
      <IconButton aria-label="grid_visibility" sx={{ color: 'text.primary' }} onClick={onGridButtonClicked}>
        {isGridOn ? <GridOffIcon/> : <GridOnIcon />}
      </IconButton>
    </Box>
  );
}

export default CanvasToolbar;
