import { connect } from 'react-redux';

import TargetView from '../components/target-view';

import { getIsSelectedTarget } from '../selectors/target';
import { selectTarget, deleteTarget, updateTargetMetaData } from '../actions/target';

const mapStateToProps = (state, props) => ({
  target: props.target,
  isSelected: getIsSelectedTarget(state, props.target.id),
});

const mapDispatchToProps = (dispatch, props) => ({
  selectTarget: () => { dispatch(selectTarget(props.target.id)); },
  removeTarget: () => { dispatch(deleteTarget(props.target.id)); },
  updateTarget: (target) => { dispatch(updateTargetMetaData(target)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(TargetView);
