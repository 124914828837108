// @flow

import React from 'react';
import Radium from 'radium';
import { Container, Row } from 'reactstrap';
import TargetView from '../containers/target-view';

const styles = {
  container: {
    width: '100%',
    margin: '0',
    padding: '8px',
    overflow: 'auto',
    height: '100%',
  },
  emptyViewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '16px',
    color: 'var(--secondaryText)',
  },
  headerView: {
    height: '48px',
    display: 'flex',
    verticalAlign: 'middle',
    alignItems: 'center',
    boxShadow: '0px 1px 0px 0px var(--dividerColor)',
    paddingLeft: '16px',
    color: 'var(--primaryText)',
    backgroundColor: '#F5F5F5',
  },
};

class TargetList extends React.Component {
  static defaultProps = {
    targets: [],
  };

  componentDidMount() {
    this.props.loadTargets(this.props.selectedProject);
  }

  headerView = () => (
    <div style={styles.headerView}>
      <h6 style={{ fontWeight: 'normal', marginBottom: '0px' }}>Targets</h6>
    </div>
  );

  emptyView = () => (
    <div style={styles.emptyViewContainer}>
      <h5>No Targets</h5>
      <p>Please upload image targets to get started.</p>
    </div>
  );

  render() {
    if (this.props.targets.length === 0) {
      return (
        <div style={{ height: 'calc(100% - 48px)' }}>
          <this.emptyView />
        </div>
      );
    }

    return (
      <Container style={styles.container}>
        <Row noGutters>
          {
            this.props.targets.map((target, index) => <TargetView key={target.id} target={target} indexNumber={++index} />)
          }
        </Row>
      </Container>
    );
  }
}

TargetList.defaultProps = {
  targets: [],
};

export default Radium(TargetList);
