import React from "react";
import Radium from "radium";
import tutoria2 from '../images/tutorial2.gif';

const styles = {
  siteHeader: {
    color: 'lightgray',
  },
  title: {
    fontSize: '30px',
  },
  subtitle: {
    fontSize: '16px',
  },
  image: {
    maxHeight: '350px',
    marginTop: '10px'
  },
  imageContainer: {
    textAlign: 'center',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    alignItems: 'center'
  },
  checkBox: {
    textAlign: 'center',
    marginTop: '0px',
    transform: 'scale(1.5)',
    marginRight: '10px'
  },
  btn: {
    padding: '10px',
    width: '100px',
    border: '0',
    borderRadius: '25px',
    backgroundColor: 'var(--primaryColor)',
    color: 'white',
    ':hover': {
      backgroundColor: 'var(--primaryHover)',
    }
  },
  nextButton: {
    background: 'transparent',
    border: 'none',
    color: '#006ae2',
    cursor: 'pointer',
    outline: 'none'
  },
  footerItemLeft: {
    width: '33%',
    textAlign: 'left',
  },
  footerItemCenter: {
    width: '33%',
    textAlign: 'center',
  },
  footerItemRight: {
    width: '33%',
    textAlign: 'right',
  }
}

export class TutorialStep2 extends  React.Component {
  next = _ => {
    this.props.jumpToStep(2)
  };

  previous = _ => {
    this.props.jumpToStep(0)
  };

  render() {
    return (
      <div>
        <span style={styles.siteHeader}>FIRST STEPS: TIP 2 OF 3</span>
        <hr></hr>
        <span style={styles.title}><b>2. Connect targets with events.</b></span>
        <br/>
        <span style={styles.subTitle}>Define what should happen when a target gets recognized.</span>
        <div style={styles.imageContainer}>
          <img style={styles.image} alt="tutorial 2" src={tutoria2}/>
        </div>
        <hr></hr>
        <div style={styles.footer}>
          <div style={styles.footerItemLeft}>
            <input style={styles.checkBox} type="checkbox"/>
            <span>Do not show tips anymore</span>
          </div>
          <div style={styles.footerItemCenter}>
            <button className="btn-shadow" style={styles.btn} onClick={this.props.onClose}>
              Let's go
            </button>
          </div>
         <div style={styles.footerItemRight}>
           <button style={styles.nextButton} onClick={this.previous}>
             <i className="fa fa-angle-double-left" style={{marginRight: '5px'}} aria-hidden="true"></i>Previous tip
           </button>
           <button style={styles.nextButton} onClick={this.next}>
             Next tip<i className="fa fa-angle-double-right" style={{marginLeft: '5px'}} aria-hidden="true"></i>
           </button>
         </div>
        </div>
      </div>
    );
  }
}

export default Radium(TutorialStep2);
