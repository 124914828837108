// @flow
import { createAction } from 'redux-actions';
import visionarService from '../services/visionar';
import { setError } from './app';

export const LOAD_TARGETS_ASYNC_REQUEST = 'LOAD_TARGETS_ASYNC_REQUEST';
export const LOAD_TARGETS_ASYNC_SUCCESS = 'LOAD_TARGETS_ASYNC_SUCCESS';
export const LOAD_TARGETS_ASYNC_FAILURE = 'LOAD_TARGETS_ASYNC_FAILURE';
export const SELECT_TARGET = 'SELECT_TARGET';
export const DELETE_TARGET_ASYNC_REQUEST = 'DELETE_TARGET_ASYNC_REQUEST';
export const DELETE_TARGET_ASYNC_SUCCESS = 'DELETE_TARGET_ASYNC_SUCCESS';
export const DELETE_TARGET_ASYNC_FAILURE = 'DELETE_TARGET_ASYNC_FAILURE';
export const UPLOAD_TARGETS_ASYNC_REQUEST = 'UPLOAD_TARGETS_ASYNC_REQUEST';
export const UPLOAD_TARGETS_ASYNC_SUCCESS = 'UPLOAD_TARGETS_ASYNC_SUCCESS';
export const UPLOAD_TARGETS_ASYNC_FAILURE = 'UPLOAD_TARGETS_ASYNC_FAILURE';
export const UPDATE_TARGET_ASYNC_REQUEST = 'UPDATE_TARGET_ASYNC_REQUEST';
export const UPDATE_TARGET_ASYNC_SUCCESS = 'UPDATE_TARGET_ASYNC_SUCCESS';
export const UPDATE_TARGET_ASYNC_FAILURE = 'UPDATE_TARGET_ASYNC_FAILURE';
export const UPDATE_UPLOAD_PERCENTAGE = 'UPDATE_UPLOAD_PERCENTAGE';

export const loadTargetsAsyncRequest = createAction(LOAD_TARGETS_ASYNC_REQUEST);
export const loadTargetsAsyncSuccess = createAction(LOAD_TARGETS_ASYNC_SUCCESS);
export const loadTargetsAsyncFailure = createAction(LOAD_TARGETS_ASYNC_FAILURE);
export const selectTarget = createAction(SELECT_TARGET);
export const deleteTargetAsyncRequest = createAction(DELETE_TARGET_ASYNC_REQUEST);
export const deleteTargetAsyncSuccess = createAction(DELETE_TARGET_ASYNC_SUCCESS);
export const deleteTargetAsyncFailure = createAction(DELETE_TARGET_ASYNC_FAILURE);
export const uploadTargetsAsyncRequest = createAction(UPLOAD_TARGETS_ASYNC_REQUEST);
export const uploadTargetsAsyncSuccess = createAction(UPLOAD_TARGETS_ASYNC_SUCCESS);
export const uploadTargetsAsyncFailure = createAction(UPLOAD_TARGETS_ASYNC_FAILURE);
export const updateTargetAsyncRequest = createAction(UPDATE_TARGET_ASYNC_REQUEST);
export const updateTargetAsyncSuccess = createAction(UPDATE_TARGET_ASYNC_SUCCESS);
export const updateTargetAsyncFailure = createAction(UPDATE_TARGET_ASYNC_FAILURE);
export const updateUploadPercentage = createAction(UPDATE_UPLOAD_PERCENTAGE);

export const loadTargets = (projectId: string) => (
  async (dispatch: Function) => {
    try {
      dispatch(loadTargetsAsyncRequest());
      const targets = await visionarService.getProjectTargets(projectId);
      dispatch(loadTargetsAsyncSuccess(targets));
    } catch (error) {
      dispatch(loadTargetsAsyncFailure());
      // dispatch(setError('Ungültiger Geheimschlüssel! Bitte geben Sie den korrekten Schlüssel ein und versuchen es erneut.'));
      localStorage.removeItem('project-key');
    }
  }
);

export const deleteTarget = (targetId: string) => (
  async (dispatch: Function) => {
    try {
      dispatch(deleteTargetAsyncRequest(targetId));
      await visionarService.deleteTarget(targetId);
      dispatch(deleteTargetAsyncSuccess(targetId));
    } catch (error) {
      dispatch(deleteTargetAsyncFailure(targetId));
    }
  }
);

export const uploadTargets = (projectId: string, files: Array<File>) => (
  async (dispatch: Function) => {
    try {
      console.log('Started the uploading process');
      dispatch(uploadTargetsAsyncRequest());
      const results = [];
      let size = 0;
      files.forEach((f) => { size += f.size; });
      const canUpload = await visionarService.validateUpload(size, files.length, projectId);
      if (canUpload) {
        try {
          files.forEach((file) => {
            results.push(visionarService.uploadTarget(file, projectId, (value) => {
              dispatch(updateUploadPercentage(value));
            }));
          });

          const uploadedTargets = await Promise.all(results);
          dispatch(uploadTargetsAsyncSuccess(uploadedTargets));
          dispatch(updateUploadPercentage(0));
        } catch (_) {
          dispatch(uploadTargetsAsyncFailure());
          dispatch(updateUploadPercentage(0));
          dispatch(setError('Upload failed. Please check your network connection'));
        }
      } else {
        dispatch(uploadTargetsAsyncFailure());
        dispatch(updateUploadPercentage(0));
        dispatch(setError('License limit reached, please purchase a new or upgrade your license.'));
      }
    } catch (error) {
      dispatch(uploadTargetsAsyncFailure());
      dispatch(updateUploadPercentage(0));
      if (error.status === 400) {
        dispatch(setError('License limit reached, please purchase a new or upgrade your license.'));
      }
    }
  }
);

export const updateTarget = (target: Target) => (
  async (dispatch: Function) => {
    try {
      dispatch(updateTargetAsyncRequest(target));
      const updatedTarget = await visionarService.updateTarget(target);
      dispatch(updateTargetAsyncSuccess(updatedTarget));
    } catch (error) {
      dispatch(updateTargetAsyncFailure(target.id));
    }
  }
);

export const updateTargetMetaData = (target) => (
  async (dispatch) => {
    try {
      const updatedTarget = await visionarService.updateTarget(target);
      dispatch(updateTargetAsyncSuccess(updatedTarget));
    } catch (e) {
      dispatch(setError(''));
    }
  }
);
