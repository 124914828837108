// @flow

import React from 'react';
import { Modal, ModalHeader, ModalBody, Progress } from 'reactstrap';

type Props = {
  isOpen: boolean,
  value: number
}

const ProgressView = ({ isOpen, value }: Props) =>
  (
    <div>
      <Modal isOpen={isOpen} backdrop="static">
        <ModalHeader>Upload in progress...</ModalHeader>
        <ModalBody>
          <Progress animated max="100" value={value} > {value} % </Progress>
        </ModalBody>
      </Modal>
    </div>
  );

export default ProgressView;
