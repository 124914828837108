import { connect } from 'react-redux';
import UserBar from '../components/user-bar';
import { getCurrentUser } from '../selectors/setting';
import { loadUser, toggleShowTutorial, resetStore } from '../actions/app';
import { recreateKeyFile } from '../actions/project';

import { getSelectedProject } from '../selectors/project';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  selectedProject: getSelectedProject(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (id) => { dispatch(loadUser(id)); },
  toggleShowTutorial: () => { dispatch(toggleShowTutorial()); },
  recreateKeyFile: (project, productKey) => { dispatch(recreateKeyFile(project, productKey)); },
  resetStore: () => { dispatch(resetStore()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBar);
