import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
// @ts-ignore
import TargetList from '../../containers/target-list';

type TargetSidebarProps = { projectId: string };

function TargetSidebar({ projectId }: TargetSidebarProps) {
  return (
    <Box sx={{
      backgroundColor: 'common.white',
      width: '315px',
      minWidth: '315px',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '2px 2px 4px -1px rgb(0 0 0 / 20%),'
        + '4px 4px 5px 0px rgb(0 0 0 / 14%),'
        + '0px 10px 1px 0px rgb(0 0 0 / 12%)',
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        pl: 1,
        borderBottom: `1px solid ${grey[200]}`,
        borderTop: `1px solid ${grey[200]}`,
      }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1, whiteSpace: 'nowrap' }}>Targets</Typography>
      </Box>
      <TargetList selectedProject={projectId} />
    </Box>
  );
}

export default TargetSidebar;
