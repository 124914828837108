// @flow

import Immutable from 'immutable';
import type { fromJS as Immut } from 'immutable';
import {
  LOAD_TARGETS_ASYNC_REQUEST,
  LOAD_TARGETS_ASYNC_SUCCESS,
  LOAD_TARGETS_ASYNC_FAILURE,
  SELECT_TARGET,
  DELETE_TARGET_ASYNC_REQUEST,
  DELETE_TARGET_ASYNC_FAILURE,
  UPDATE_TARGET_ASYNC_REQUEST,
  UPDATE_TARGET_ASYNC_SUCCESS,
  UPDATE_TARGET_ASYNC_FAILURE,
  UPLOAD_TARGETS_ASYNC_REQUEST,
  UPLOAD_TARGETS_ASYNC_SUCCESS,
  UPLOAD_TARGETS_ASYNC_FAILURE,
  UPDATE_UPLOAD_PERCENTAGE, updateTarget,
} from '../actions/target';
import {
  SELECT_TARGET_ITEM,
  UPLOAD_CONTENT_ASYNC_FAILURE, UPLOAD_CONTENT_ASYNC_REQUEST,
  UPLOAD_CONTENT_ASYNC_SUCCESS,
} from '../actions/content';

export const statusEnum = Object.freeze({
  PROCESSING: Symbol('processing'),
  SUCCESS: Symbol('success'),
  FAILED: Symbol('failed'),
});

const initialState = Immutable.fromJS({
  targets: [],
  selectedTarget: undefined,
  selectedItem: undefined,
  targetsStatus: undefined,
  isUploading: false,
  uploadPercentage: 0,
});

let snapshot;

const targetReducer = (state: Immut = initialState, action: { type: string, payload: any }) => {
  switch (action.type) {
    case LOAD_TARGETS_ASYNC_REQUEST:
      return state.set('targetsStatus', statusEnum.PROCESSING);
    case LOAD_TARGETS_ASYNC_SUCCESS: {
      const targets = action.payload;
      return state.set('targets', Immutable.fromJS(targets))
        .set('selectedTarget', targets.length > 0 ? targets[0].id : undefined)
        .set('targetsStatus', statusEnum.SUCCESS);
    }
    case LOAD_TARGETS_ASYNC_FAILURE:
      return state.set('targetsStatus', statusEnum.FAILED);
    case SELECT_TARGET: {
      const indexOfTarget = state.get('targets').findIndex((target) => target.get('id') === action.payload);

      return indexOfTarget >= 0 ? state.set('selectedTarget', action.payload) : state;
    }
    case DELETE_TARGET_ASYNC_REQUEST: {
      snapshot = state;
      const indexOfTarget = state.get('targets').findIndex((target) => target.get('id') === action.payload);

      const newState = state.removeIn(['targets', indexOfTarget]);
      return newState.set('selectedTarget', newState.getIn(['targets', 0, 'id']));
    }
    case DELETE_TARGET_ASYNC_FAILURE: {
      return Immutable.fromJS(snapshot);
    }
    case UPDATE_TARGET_ASYNC_REQUEST: {
      snapshot = state;
      const indexOfTarget = state.get('targets').findIndex((target) => target.get('id') === action.payload.get('id'));

      return state.setIn(['targets', indexOfTarget], Immutable.fromJS(action.payload));
    }
    case UPDATE_TARGET_ASYNC_SUCCESS: {
      const indexOfTarget = state.get('targets').findIndex((target) => target.get('id') === action.payload.id);

      const result = state.set('isUploading', false);
      return result.setIn(['targets', indexOfTarget], Immutable.fromJS(action.payload));
    }
    case UPDATE_TARGET_ASYNC_FAILURE: {
      return Immutable.fromJS(snapshot);
    }
    case UPLOAD_TARGETS_ASYNC_REQUEST:
      return state.set('isUploading', true);
    case UPLOAD_TARGETS_ASYNC_SUCCESS: {
      const immutableTargets = action.payload.map((target) => (
        Immutable.fromJS(target)
      ));

      const currentSelectedTarget = state.get('selectedTarget');
      const result = state.set('isUploading', false);
      return result.update('targets', (arr) => arr.push(...immutableTargets))
        .set('selectedTarget', currentSelectedTarget !== undefined ? currentSelectedTarget
          : action.payload !== undefined ? action.payload[0].id : undefined);
    }
    case UPLOAD_TARGETS_ASYNC_FAILURE: {
      return state.set('isUploading', false);
    }
    case UPLOAD_CONTENT_ASYNC_REQUEST: {
      return state.set('isUploading', true);
    }
    case UPLOAD_CONTENT_ASYNC_SUCCESS: {
      const indexOfTarget = state.get('targets').findIndex((target) => target.get('id') === action.payload.id);

      const result = state.set('isUploading', false);
      return result.setIn(['targets', indexOfTarget], Immutable.fromJS(action.payload));
    }
    case UPLOAD_CONTENT_ASYNC_FAILURE: {
      return state.set('isUploading', false);
    }
    case UPDATE_UPLOAD_PERCENTAGE: {
      return state.set('uploadPercentage', action.payload);
    }
    case SELECT_TARGET_ITEM: {
      return state.set('selectedItem', action.payload);
    }
    default:
      return state;
  }
};

export default targetReducer;
