import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const styles = {
  okButton: {
    width: '100px',
    borderRadius: '25px',
    marginTop: '30px',
    marginBottom: '16px',
    marginRight: '16px',
  },
};

export class ConfirmDialog extends React.Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} backdrop="static">
          <ModalHeader>
            <i className="fa fa-exclamation-circle" style={{ marginRight: '15px', color: 'red' }} />
            {this.props.title}
          </ModalHeader>
          <ModalBody>
            {this.props.message}
          </ModalBody>
          <div>
            <button className="btn float-right btn-danger" onClick={() => this.props.onConfirmClick()} style={styles.okButton}>{this.props.okTitle}</button>
            <button className="btn float-right btn-secondary" onClick={() => this.props.onCancelClick()} style={styles.okButton}>Cancel</button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ConfirmDialog;
