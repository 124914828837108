import React from 'react';
// @ts-ignore
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
// @ts-ignore
import PreviewInstructions from '../../containers/preview-instructions';
import Project from '../../models/project';

type PreviewSidebarProps = { isOpen: boolean, project: Project };

function PreviewSidebar({ isOpen, project }: PreviewSidebarProps) {
  return (
    <Box sx={{
      backgroundColor: 'common.white',
      width: isOpen ? '320px' : '0',
      minWidth: isOpen ? '320px' : '0',
      zIndex: 1,
      // transition: 'width 0.5s', caused the TargetItems to be out of position
      animationFillMode: 'backwards',
      display: 'flex',
      overflowX: 'hidden',
      flexDirection: 'column',
      boxShadow: '-2px 2px 4px -1px rgb(0 0 0 / 20%),'
        + '-4px 4px 5px 0px rgb(0 0 0 / 14%),'
        + '-0px 10px 1px 0px rgb(0 0 0 / 12%)',
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        pl: 1,
        borderBottom: `1px solid ${grey[200]}`,
        borderTop: `1px solid ${grey[200]}`,
      }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1, whiteSpace: 'nowrap' }}>How to test your project</Typography>
      </Box>
      <PreviewInstructions isPreview={isOpen} project={project} />
    </Box>
  );
}

export default PreviewSidebar;
