import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Card } from 'reactstrap';
import AddIcon from '@mui/icons-material/Add';
import { useCreateProjectMutation } from '../services/visionarRTK';
import SetupWizard from '../containers/setup-wizard';

const useStyles = makeStyles((theme) => ({
  newLicenseIcon: {
    paddingTop: '16px',
  },
  card: {
    width: '300px',
    height: '200px',
    margin: '12.5px',
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid var(--dividerColor)',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    color: theme.palette.primary.main,
    textAlign: 'center',
    textDecoration: 'none !important',
  },
}));

const ProjectItemNew = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Card onClick={() => setOpen(true)} className={classes.card}>
        <div style={{
          height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <span>
            <AddIcon fontSize="large" />
            <p className={`${classes.newLicenseIcon} btn-text`}>Add Project</p>
          </span>
        </div>
      </Card>
      <SetupWizard isOpen={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default ProjectItemNew;
