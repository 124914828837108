import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
import { useRequestPasswordResetMutation } from '../../services/visionarRTK';
import Copyright from '../copyright';
import visionarLogo from '../../images/visionar_logo_2021_white.svg';
import backgroundImage from '../../images/background.png';
import { validateEmail } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '100vh',
    paddingTop: theme.spacing(10),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    backgroundColor: 'white',
    marginTop: theme.spacing(6),
    padding: theme.spacing(8),
    boxShadow: '0px 4px 24px rgb(0 0 0 / 10%)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      margin: theme.spacing(1),
      padding: theme.spacing(4),
      width: 'auto',
      boxShadow: 'none',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBar: {
    boxShadow: 'none',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  logo: {
    width: '187px',
    maxWidth: '187px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      maxWidth: '150px',
    },
  },
  signUpButton: {
    marginLeft: theme.spacing(2),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  body: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    height: '50px',
    maxWidth: '100%',
    color: 'rgba(255, 255, 255, 0.7)',
  },
  separator: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.50)',
    '&::before': {
      content: '""',
      flex: '1',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    },
    '&::after': {
      content: '""',
      flex: '1',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    },
    '&:not(:empty)::before': {
      marginRight: '.25em',
    },
    '&:not(:empty)::after': {
      marginLeft: '.25em',
    },
  },
  errorMessageHelperText: {
    color: theme.palette.error.main,
  },
  button: {
    margin: theme.spacing(1, 0, 2),
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [requestPasswordReset, { isLoading }] = useRequestPasswordResetMutation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [email, setEmail] = useState('');
  const [emailValidation, setEmailValidation] = useState(null);
  const [error, setError] = useState(null);

  const validateForm = () => {
    let errorFound = false;
    if (!email || !validateEmail(email)) {
      setEmailValidation('Please enter a correct email address.');
      errorFound = true;
    }

    return !errorFound;
  };

  const onFormSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!validateForm()) return;
      const result = await requestPasswordReset({ email });
      if (!result.error) {
        history.push('/forgotPasswordSuccess');
      } else {
        setError('unknown_error');
      }
    } catch (err) {
      setError('unknown_error');
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Toolbar>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <img src={visionarLogo} alt="logo" className={classes.logo} />
            </Grid>
            {
              matches
                ? (
                  <Grid item>
                    <Typography variant="body1" display="inline">Don&apos;t have an account?</Typography>
                    <Button
                      color="inherit"
                      className={classes.signUpButton}
                      component={RouterLink}
                      variant="outlined"
                      to="signup#account-setup"
                    >
                      SIGN UP NOW
                    </Button>
                  </Grid>
                )
                : (
                  <Button
                    color="inherit"
                    className={classes.signUpButton}
                    component={RouterLink}
                    size="small"
                    variant="outlined"
                    to="signup#account-setup"
                  >
                    SIGN UP
                  </Button>
                )
            }
          </Grid>
        </Toolbar>
      </AppBar>

      <div>
        <Container component="main" maxWidth="xs" className={classes.container}>
          <CssBaseline />

          <div className={classes.paper}>
            {
              matches
                ? (
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                )
                : null
            }
            <Typography component="h1" variant="h5">Forgot your password?</Typography>
            <Typography variant="body1" className={classes.body}>
              To reset your password, please enter the email address
              of your visionar account.
            </Typography>
            <form className={classes.form} noValidate onSubmit={async (e) => onFormSubmit(e)}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                error={emailValidation != null}
                helperText={emailValidation}
                name="email"
                value={email}
                disabled={isLoading}
                onChange={(e) => {
                  setEmailValidation(null);
                  setEmail(e.target.value);
                }}
                autoComplete="email"
                autoFocus
                aria-describedby="email-helper-text"
              />
              {
                error
                  ? (
                    <Alert icon={false} severity="error">
                      <span>
                        An authentication exception occurred. Please try again or
                        <a href="mailto:support@visionar.com"> contact support</a>
                        .
                      </span>
                    </Alert>
                  ) : null
              }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading}
                className={classes.button}
              >
                {isLoading ? <CircularProgress size={24} /> : <span>Reset my password</span>}
              </Button>
            </form>
            <div className={classes.separator}>or</div>
            <Button color="primary" component={RouterLink} to="/login">Go to login</Button>
          </div>

        </Container>
      </div>

      <Container xs={12} className={classes.footer}>
        <Copyright />
      </Container>
    </div>
  );
};

export default ForgotPassword;
