/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { StyleRoot } from 'radium';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { useAuth } from './hooks/use-auth';
import Login from './containers/login';
import SignUpFlow from './components/pages/sign-up-flow';
import Projects from './components/pages/projects';
import EditorPage from './containers/editor-page';
import ForgotPassword from './components/pages/forgot-password';
import ResetPassword from './components/pages/reset-password';
import CheckEmail from './components/pages/check-email';
import ResetPasswordSuccess from './components/pages/reset-password-success';
import PurchaseSuccess from './components/pages/purchase-success';
import Playground from './components/pages/playground';

function App(props) {
  return (
    <StyleRoot>
      <Router>
        <div>
          <Switch>
            <PrivateRoute exact path="/">
              <Projects />
            </PrivateRoute>
            <PrivateRoute path="/editor/:id">
              <EditorPage {...props} />
            </PrivateRoute>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <SignUpFlow />
            </Route>
            <Route path="/forgotPassword">
              <ForgotPassword />
            </Route>
            <Route path="/resetPassword">
              <ResetPassword />
            </Route>
            <Route path="/forgotPasswordSuccess">
              <CheckEmail />
            </Route>
            <Route path="/resetPasswordSuccess">
              <ResetPasswordSuccess />
            </Route>
            <Route path="/purchaseSuccess">
              <PurchaseSuccess />
            </Route>
            { process.env.NODE_ENV === 'development'
              ? (
                <Route path="/playground">
                  <Playground />
                </Route>
              ) : null}
          </Switch>
        </div>
      </Router>
    </StyleRoot>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  const childrenWithProps = React.cloneElement(children, { ...rest });
  return (
    <Route
      {...rest}
      render={({ location }) => (auth.sessionToken ? (
        childrenWithProps
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

export default App;
