import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import AddIcon from '@mui/icons-material/Add';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useTheme } from '@mui/material/styles';
import visionarEyeIcon from '../../images/visionar_studio_eye.svg';

type EditorAppBarProps = {
  previewMode: boolean,
  projectName: string,
  exitButtonClick: React.MouseEventHandler<HTMLButtonElement>,
  addTargetButtonClick: React.MouseEventHandler<HTMLButtonElement>,
  addComponentButtonClick: React.MouseEventHandler<HTMLButtonElement>,
  previewButtonClick: React.MouseEventHandler<HTMLButtonElement>
};

function RenderPreviewContent(previewButtonClick: React.MouseEventHandler<HTMLButtonElement>) {
  return <Button variant="contained" color="success" onClick={previewButtonClick}>Exit Preview</Button>;
}

function RenderDefaultContent(
  projectName: string,
  previewButtonClick: React.MouseEventHandler<HTMLButtonElement>,
  addTargetButtonClick: React.MouseEventHandler<HTMLButtonElement>,
  addComponentButtonClick: React.MouseEventHandler<HTMLButtonElement>,
) {
  return (
    <>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={addTargetButtonClick}>
        Targets
      </Button>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={addComponentButtonClick} sx={{ ml: 1 }}>
        Components
      </Button>
      <Button variant="contained" startIcon={<SmartphoneIcon />} onClick={previewButtonClick} sx={{ ml: 1 }}>
        Preview
      </Button>
    </>
  );
}

function EditorAppBar({
  previewMode,
  projectName,
  previewButtonClick,
  exitButtonClick,
  addTargetButtonClick,
  addComponentButtonClick,
}: EditorAppBarProps) {
  const theme = useTheme();
  return (
    <AppBar position="static" sx={{ backgroundColor: 'common.white', transform: 'translateZ(0)' }}>
      <Toolbar>
        <img
          src={visionarEyeIcon}
          style={{ marginRight: theme.spacing(2), marginLeft: theme.spacing(-1) }}
          alt="visionar_logo"
        />
        <Button
          variant="outlined"
          startIcon={<ShortcutIcon sx={{ transform: 'scaleX(-1)' }} />}
          onClick={exitButtonClick}
          sx={{ mr: 1 }}
        >
          Exit
        </Button>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'text.primary', ml: 1 }}>
          {projectName}
        </Typography>
        {previewMode
          ? RenderPreviewContent(previewButtonClick)
          : RenderDefaultContent(projectName, previewButtonClick, addTargetButtonClick, addComponentButtonClick)}
      </Toolbar>
    </AppBar>
  );
}

export default EditorAppBar;
