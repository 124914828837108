import React from 'react';
import { Container, Row } from 'reactstrap';
import CircularProgress from '@mui/material/CircularProgress';
import ProjectItem from '../containers/project-item';
import ProjectItemNew from './project-item-new';
import { useGetUserProjectsQuery } from '../services/visionarRTK';

const styles = {
  container: {
    width: '975px',
    margin: '130px auto 0',
  },
};

const ProjectList = () => {
  const { data, isLoading } = useGetUserProjectsQuery();
  return (
    <Container style={styles.container}>
      <h5 style={{ color: 'var(--text)' }}>Your Projects</h5>
      <Row>
        <ProjectItemNew />
        {
          isLoading ? <CircularProgress style={{ margin: 'auto' }} size={24} />
            : data ? data.map((project) => <ProjectItem lg="2" key={project._id} item={project} />) : null
        }
      </Row>
    </Container>
  );
};

export default ProjectList;
