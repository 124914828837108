import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import PropTypes, { func } from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PlayImage from '../images/play.png';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ConfirmDialog from './confirm-dialog';
import { useDropzone } from 'react-dropzone';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';

const useStyles = makeStyles((theme) => ({
  container: {
    border: 'none',
    zIndex: '1',
    backgroundColor: null,
    padding: '2px'
  },
  containerSelected: {
    border: `solid 2px ${theme.palette.primary.main}`,
    zIndex: '1',
    backgroundColor: `${theme.palette.primary.light}30`,
  },
  dropzone: {
    width: '100%',
    height: '100%',
  },
  label: {
    display: 'none',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: '-17px',
    left: '-2px',
    float: 'left',
    padding: '0px 6px',
    lineHeight: '15px',
    height: '16px',
    color: theme.palette.common.white,
  },
  labelSelected: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: '-17px',
    left: '-2px',
    display: 'inline-block',
    float: 'left',
    padding: '0px 6px',
    lineHeight: '15px',
    height: '16px',
    color: theme.palette.common.white,
  },
  resizeHandleTopLeft: {
    width: '10px !important',
    height: '10px !important',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    top: '-5px !important',
    left: '-5px !important',
    cursor: 'nwse-resize !important',
    zIndex: 1,
  },
  resizeHandleTop: {
    width: '10px !important',
    height: '10px !important',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    left: '50% !important',
    marginLeft: '-8px',
    cursor: 'ns-resize !important',
    zIndex: 1,
  },
  resizeHandleTopRight: {
    width: '10px !important',
    height: '10px !important',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    top: '-5px !important',
    right: '-5px !important',
    cursor: 'nesw-resize !important',
    zIndex: 1,
  },
  resizeHandleRight: {
    width: '10px !important',
    height: '10px !important',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    top: '50% !important',
    right: '-6px !important',
    cursor: 'ew-resize !important',
    zIndex: 1,
  },
  resizeHandleBottomRight: {
    width: '10px !important',
    height: '10px !important',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    bottom: '-5px !important',
    right: '-5px !important',
    cursor: 'nwse-resize !important',
    zIndex: 1,
  },
  resizeHandleBottom: {
    width: '10px !important',
    height: '10px !important',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    left: '50% !important',
    marginLeft: '-8px',
    cursor: 'ns-resize !important',
    zIndex: 1,
  },
  resizeHandleBottomLeft: {
    width: '10px !important',
    height: '10px !important',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    bottom: '-5px !important',
    left: '-5px !important',
    cursor: 'nesw-resize !important',
    zIndex: 1,
  },
  resizeHandleLeft: {
    width: '10px !important',
    height: '10px !important',
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    top: '50% !important',
    left: '-6px !important',
    cursor: 'ew-resize !important',
    zIndex: 1,
  },
  playImage: {
    objectFit: 'contain',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto',
    width: '50%',
    maxWidth: '128px',
    maxHeight: '128px',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  },
  imageContentPreview: {
    width: '100%',
    height: '100%',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  },
  deleteIcon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(0.5)
  },
}));

// let dropzoneRef;
// let instantOpenDropzone;
// const styles = {
//   targetItem: {
//     border: 'solid 2px #4782DA',
//     backgroundColor: '#b1c8cd',
//     opacity: '0.9',
//   },
//   deleteButton: {
//     border: '0',
//     position: 'absolute',
//     top: '0',
//     right: '0',
//     backgroundColor: 'transparent',
//     cursor: 'pointer'
//   },
//   maximizeButton: {
//     border: '0',
//     position: 'absolute',
//     top: '0',
//     left: '0',
//     backgroundColor: 'transparent',
//     outline: 'none',
//     cursor: 'pointer'
//   },
//   dropzone: {
//     width: '100%',
//     height: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     flexDirection: 'column',
//   },
//   dropzoneActive: {
//     backgroundColor: '#d2edc1',
//     borderStyle: 'solid',
//     borderColor: 'limegreen',
//   },
//   dropzoneReject: {
//     backgroundColor: '#edabb2',
//     borderStyle: 'solid',
//     borderColor: 'red',
//   },
//   uploadButton: {
//     backgroundColor: '#115CA8',
//     color: 'white',
//     border: '0',
//     borderRadius: '0',
//     marginTop: '15px',
//     paddingLeft: '10px',
//     paddingRight: '10px',
//     ':hover': {
//       backgroundColor: '#006ae2',
//     },
//   },
//   itemName: {
//     color: 'white',
//     marginTop: '10px',
//     fontSize: '18px',
//     marginBottom: '0px'
//   },
//   error: {
//     color: 'red',
//     marginBottom: '0px',
//     fontSize: '14px',
//   },
//   textField: {
//     width: '90%',
//     textAlign: 'center',
//     marginTop: '0px',
//     marginBottom: '-10px',
//   },
//   checkBox: {
//     textAlign: 'center',
//     marginTop: '0px',
//     transform: 'scale(1.5)',
//     marginLeft: '10px'
//   },
//   checkBoxContainer: {
//     marginBottom: '-20px',
//     color: 'white',
//     fontSize: '18px',
//   },
// };

function TargetItem({
                      item,
                      scaleFactor,
                      originalImageSize,
                      updateItem,
                      removeItem,
                      selectItem,
                      isSelected,
                      canvasSize,
                      uploadContent,
                      targetId,
                      canvasScale,
                    }) {


  function getAcceptedFiles() {
    if (item.type === 'VIDEO') {
      return "video/mp4, video/quicktime";
    } else if (item.type === 'IMAGE' ) {
      return "image/jpeg, image/png";
    } else if (item.type === 'LINK' ) {
      return "image/jpeg, image/png";
    } else if (item.type === 'AUDIO' ) {
      return "audio/mpeg, audio/mp3";
    } else if (item.type === 'GREEN_SCREEN' ) {
      return "video/mp4, video/quicktime";
    }

    return "application/x-empty";
  }

  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [error, setError] = useState(null);
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: getAcceptedFiles(),
    multiple: false,
    maxSize: 52428800 * 10,
    onDropAccepted: (files) => onDropAccepted(files),
    onDropRejected: () => onDropRejected(),
  });

  let label = '';
  switch (item.type) {
    case 'IMAGE':
      label = 'Image';
      break;
    case 'VIDEO':
      label = 'Video Player';
      break;
    case 'AUDIO':
      label = 'Audio Player';
      break;
    case 'LINK':
      label = 'Link';
      break;
    case 'GREEN_SCREEN':
      label = 'Green Screen';
      break;
    default:
      label = `Unknown Type: ${item.type}`;
  }

  useEffect(() => {
    if (!item.url) {
      open();
    }
  }, []);

  const onDropRejected = (fileRejections) => {
    console.log(JSON.stringify(fileRejections));
    setError('File could not be uploaded: Max upload size is 500MB');
  }

  const onDropAccepted = (acceptedFile) => {
    setError(null);
    uploadContent({
      file: acceptedFile,
      itemId: item.id,
      targetId: targetId
    });
  }

  function sizeChanged(width, height, x, y) {
    const newItem = { ...item };
    newItem.width = width / scaleFactor;
    newItem.height = height / scaleFactor;
    newItem.targetWidth = originalImageSize.width;
    newItem.targetHeight = originalImageSize.height;
    newItem.x = (x / scaleFactor - canvasSize.width / 2) + newItem.width / 2;
    newItem.y = ((y / scaleFactor - canvasSize.height / 2) * -1) - newItem.height / 2;
    updateItem(newItem);
  }

  function positionChanged(x, y) {
    const oldX = item.x;
    const oldY = item.y;
    const newItem = Object.assign({}, item);
    newItem.targetWidth = originalImageSize.width;
    newItem.targetHeight = originalImageSize.height;
    newItem.x = (x / scaleFactor - canvasSize.width / 2) + newItem.width / 2;
    newItem.y = ((y / scaleFactor - canvasSize.height / 2) * -1) - newItem.height / 2;

    if (oldX !== newItem.x || oldY !== newItem.y) {
      updateItem(newItem);
    }
  }

  function maximizeItem() {
    const newItem = { ...item };
    newItem.x = 0;
    newItem.y = 0;
    newItem.width = originalImageSize.width;
    newItem.height = originalImageSize.height;
    updateItem(newItem);
  }

  function RenderContentPreview() {
    switch (item.type) {
      case 'IMAGE':
        if (!item.url) return (
          <Box sx={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <PhotoSizeSelectActualOutlinedIcon sx={{color: 'text.secondary', fontSize: '90px' }}/>
          </Box>
        );

        return <img key={item.url} src={item.url} className={classes.imageContentPreview} />;
      case 'VIDEO':
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <img src={PlayImage} className={classes.playImage} />
            {isSelected ? <Typography variant="body2">{item.name}</Typography> : null}
          </div>
        );
      case 'GREEN_SCREEN':
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <img src={PlayImage} className={classes.playImage} />
            {isSelected ? <Typography variant="body2">{item.name}</Typography> : null}
          </div>
        );
      case 'AUDIO':
        return (
          <div style={{ width: '100%', height: '100%' }}>
            <img src={PlayImage} className={classes.playImage} />
            {isSelected ? <Typography variant="body2">{item.name}</Typography> : null}
          </div>
        );
      case 'LINK':
        if (!item.url) return (
          <Box sx={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <PhotoSizeSelectActualOutlinedIcon sx={{color: 'text.secondary', fontSize: '90px' }}/>
          </Box>
        );

        return <img key={item.url} src={item.url} className={classes.imageContentPreview} />;
      default:
        return null;
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  const handleFullScreenChanged = (e) => {
    const newItem = Object.assign({}, item);
    newItem.isFullscreen = e.target.checked;
    updateItem(newItem);
  }

  const handleLinkChanged = (e) => {
    const newItem = Object.assign({}, item);
    newItem.linkUrl = e.target.value;
    updateItem(newItem);
  }

  const handleGreenScreenColorChanged = (e) => {
    const newItem = Object.assign({}, item);
    newItem.greenScreenColor = e.target.value;
    updateItem(newItem);
  }

  return (
    <Rnd
      className={isSelected ? classes.containerSelected : classes.container}
      bounds="#canvas"
      enableResizing={isSelected}
      minHeight={50}
      minWidth={50}
      size={{ width: item.width * scaleFactor, height: item.height * scaleFactor }}
      scale={canvasScale}
      position={{
        x: ((item.x + canvasSize.width / 2) - item.width / 2) * scaleFactor,
        y: (((item.y - canvasSize.height / 2) * -1) - item.height / 2) * scaleFactor
      }}
      resizeHandleClasses={
        isSelected
          ? {
            topLeft: classes.resizeHandleTopLeft,
            top: classes.resizeHandleTop,
            topRight: classes.resizeHandleTopRight,
            right: classes.resizeHandleRight,
            bottomRight: classes.resizeHandleBottomRight,
            bottom: classes.resizeHandleBottom,
            bottomLeft: classes.resizeHandleBottomLeft,
            left: classes.resizeHandleLeft,
          } : {}
      }
      onDragStart={(e, data) => {
        selectItem(item.id)
      }}
      onDragStop={(e, d) => {
        positionChanged(d.x, d.y);
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        sizeChanged(ref.offsetWidth, ref.offsetHeight, position.x, position.y);
      }}
    >
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <div className={isSelected ? classes.labelSelected : classes.label}>
          <Typography variant="caption">{label}</Typography>
        </div>
        <RenderContentPreview />
        {isSelected
          ? (
            <Box
              sx={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                position: 'absolute',
                bottom: '16px',
                left: '0',
                right: '0',
                width: 'fit-content',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                bgcolor: 'background.paper',
                color: 'text.primary',
                '& hr': {
                  mx: 0.5,
                },
              }}
            >
              <Button
                size="small" onClick={open} sx={{
                color: 'text.primary',
                paddingTop: '6px',
                ml: 0.5,
                whiteSpace: 'nowrap'
              }}
              >Upload</Button>
              <Divider orientation="vertical" flexItem />

              {
                item.type === 'VIDEO' ? (
                  <>
                    <FormGroup sx={{ mr: 1 }}>
                      <FormControlLabel
                        sx={{ m: 0 }}
                        control={<Checkbox size="small" checked={item.isFullscreen} onChange={handleFullScreenChanged} />}
                        label="Fullscreen"
                      />
                    </FormGroup>
                    <Divider orientation="vertical" flexItem />
                  </>
                ) : null
              }

              {/*{*/}
              {/*  item.type === 'GREEN_SCREEN' ? (*/}
              {/*    <>*/}
              {/*      <FormGroup sx={{ mr: 1 }}>*/}
              {/*        <TextField placeholder="Hex Color" variant="standard" size="small" margin="dense" InputLabelProps={{ shrink: false }} inputProps={{ maxLength: 7 }} defaultValue={item.greenScreenColor} onBlur={handleGreenScreenColorChanged}/>*/}
              {/*      </FormGroup>*/}
              {/*      <Divider orientation="vertical" flexItem />*/}
              {/*    </>*/}
              {/*  ) : null*/}
              {/*}*/}

              {
                item.type === 'LINK' ? (
                  <>
                    <FormGroup sx={{ mr: 1 }}>
                      <TextField placeholder="Url" variant="standard" size="small" margin="dense" InputLabelProps={{ shrink: false }} onBlur={handleLinkChanged} defaultValue={item.linkUrl} />
                    </FormGroup>
                    <Divider orientation="vertical" flexItem />
                  </>
                ) : null
              }

              <IconButton aria-label="fullscreen" className={classes.deleteIcon} onClick={() => maximizeItem()}>
                <FullscreenIcon />
              </IconButton>
              <IconButton aria-label="delete" className={classes.deleteIcon} onClick={() => setConfirmOpen(true)}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Box>
          )
          : null}


      </div>
      <ConfirmDialog
        isOpen={confirmOpen}
        title="Confirm Delete"
        message="Are you sure you want to remove this item?"
        okTitle="Remove"
        onCancelClick={() => setConfirmOpen(false)}
        onConfirmClick={() => removeItem(item)}
      />
      <Snackbar open={error != null} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Rnd>
  );
}

// class TargetItem extends React.Component<Props> {
//   constructor(props) {
//     super(props)
//     this.state = { error: null, confirmOpen: false, onButtonHover: false };
//   }
//
//   componentDidMount() {
//     instantOpenDropzone = true;
//   }
//
//   componentDidUpdate(prevProps, prevState, snapshot) {
//     this.openDropzone();
//   }
//
//   sizeChanged(width: number, height: number, x: number, y: number) {
//     const newItem = Object.assign({}, this.props.item);
//     newItem.width = width * this.props.scaleFactor;
//     newItem.height = height * this.props.scaleFactor;
//     newItem.targetWidth = this.props.originalImageSize.width;
//     newItem.targetHeight = this.props.originalImageSize.height;
//     newItem.x = (x * this.props.scaleFactor - this.props.originalImageSize.width / 2) + newItem.width / 2;
//     newItem.y = ((y * this.props.scaleFactor - this.props.originalImageSize.height / 2) * -1) - newItem.height / 2;
//     console.log('sizeChanged called');
//     this.props.updateItem(newItem);
//   }
//
//   positionChanged(x: number, y: number) {
//     const oldX = this.props.item.x;
//     const oldY = this.props.item.y;
//     const newItem = Object.assign({}, this.props.item);
//     newItem.targetWidth = this.props.originalImageSize.width;
//     newItem.targetHeight = this.props.originalImageSize.height;
//     newItem.x = (x * this.props.scaleFactor - this.props.originalImageSize.width / 2) + newItem.width / 2;
//     newItem.y = ((y * this.props.scaleFactor - this.props.originalImageSize.height / 2) * -1) - newItem.height / 2;
//
//     if (oldX !== newItem.x || oldY !== newItem.y) {
//       this.props.updateItem(newItem);
//     }
//   }
//
//   maximizeItem() {
//     const newItem = Object.assign({}, this.props.item);
//     newItem.x = 0;
//     newItem.y = 0;
//     newItem.width = this.props.originalImageSize.width;
//     newItem.height = this.props.originalImageSize.height;
//     this.props.updateItem(newItem);
//   }
//
//   linkChanged(link: string) {
//     const newItem = Object.assign({}, this.props.item);
//     newItem.linkUrl = link;
//     this.props.updateItem(newItem);
//   }
//
//   onDropRejected = () => {
//     this.setState({ error: 'Max upload size is 50MB' });
//   }
//
//   onDropAccepted = (acceptedFile) => {
//     this.setState({ error: null });
//     this.uploadContent(acceptedFile);
//   }
//
//   uploadContent(acceptedFile) {
//     this.props.uploadContent({
//       file: acceptedFile,
//       itemId: this.props.item.id,
//       targetId: this.props.targetId
//     });
//   }
//
//   openDropzone() {
//     if (dropzoneRef && instantOpenDropzone && !this.props.item.url) {
//       dropzoneRef.open();
//       instantOpenDropzone = false;
//     }
//   }
//
//   isFullScreenChanged(isFullScreen) {
//     const newItem = Object.assign({}, this.props.item);
//     newItem.isFullscreen = isFullScreen;
//     this.props.updateItem(newItem);
//   }
//
//   render() {
//     let contentView;
//     if (this.props.item.url) {
//       contentView = <p style={styles.itemName}>{this.props.item.name}</p>
//     } else {
//       contentView = <button
//         className="mx-auto"
//         style={styles.uploadButton}
//         type="button"
//         onClick={() => { dropzoneRef.open(); }}>
//         UPLOAD FILE
//                     </button>
//     }
//
//     let acceptedType = "application/x-empty";
//     if (this.props.item.type === 'VIDEO' && !this.props.item.url) {
//       acceptedType = "video/mp4, video/quicktime";
//     } else if (this.props.item.type === 'IMAGE' && !this.props.item.url) {
//       acceptedType = "image/jpeg, image/png";
//     } else if (this.props.item.type === 'LINK' && !this.props.item.url) {
//       acceptedType = "image/jpeg, image/png";
//     } else if (this.props.item.type === 'AUDIO' && !this.props.item.url) {
//       acceptedType = "audio/mpeg, audio/mp3";
//     }
//
//     return (
//       <Rnd
//         style={this.props.item.isSelected ? styles.targetItem : styles.targetItem}
//         bounds={'#targetImage'}
//         size={{ width: this.props.item.width / this.props.scaleFactor, height: this.props.item.height / this.props.scaleFactor }}
//         position={{ x: (this.props.item.x + this.props.originalImageSize.width / 2) / this.props.scaleFactor - this.props.item.width / this.props.scaleFactor / 2, y: (((this.props.item.y - this.props.originalImageSize.height / 2) / this.props.scaleFactor) * -1) - this.props.item.height / this.props.scaleFactor / 2 }}
//         onDragStart={() => {
//           console.log('drag started');
//         }}
//         onDragStop={(e, d) => { this.positionChanged(d.x, d.y); }}
//         onResizeStop={(e, direction, ref, delta, position) => {
//           this.sizeChanged(ref.offsetWidth, ref.offsetHeight, position.x, position.y);
//         }}
//         minHeight={150}
//         minWidth={150}
//         disableDragging={this.state.confirmOpen || this.state.onButtonHover}
//         resizeHandleStyles={{
//           bottomRight: {
//             width: '10px',
//             height: '10px',
//             backgroundColor: '#fff',
//             border: '1px solid #4782DA',
//             borderRadius: '50%',
//             bottom: '-5px',
//             right: '-5px',
//             cursor: 'nwse-resize',
//             zIndex: 100,
//           }
//         }}
//       >
//         <Dropzone
//           style={styles.dropzone}
//           activeStyle={styles.dropzoneActive}
//           rejectStyle={styles.dropzoneReject}
//           accept={acceptedType}
//           multiple={false}
//           maxSize={52428800 * 10}
//           onDropAccepted={this.onDropAccepted}
//           onDropRejected={this.onDropRejected}
//           disableClick
//           ref={(node) => { dropzoneRef = node; }}
//         >
//           {({getRootProps, getInputProps}) => (
//             <section className="container">
//               <div {...getRootProps({className: 'dropzone'})}>
//                 <input {...getInputProps()} />
//                 <p>Drag 'n' drop some files here, or click to select files</p>
//               </div>
//             </section>
//           )}
//         </Dropzone>
//         {/*// <Dropzone*/}
//         {/*//   style={styles.dropzone}*/}
//         {/*//   activeStyle={styles.dropzoneActive}*/}
//         {/*//   rejectStyle={styles.dropzoneReject}*/}
//         {/*//   accept={acceptedType}*/}
//         {/*//   multiple={false}*/}
//         {/*//   maxSize={52428800 * 10}*/}
//         {/*//   onDropAccepted={this.onDropAccepted}*/}
//         {/*//   onDropRejected={this.onDropRejected}*/}
//         {/*//   disableClick*/}
//         {/*//   ref={(node) => { dropzoneRef = node; }}*/}
//         {/*// >*/}
//         {/*//   <button style={styles.deleteButton} onMouseOver={() => this.setState({ onButtonHover: true })} onMouseOut={() => this.setState({onButtonHover: false})} onClick={() => this.setState({ confirmOpen: true })}>*/}
//         {/*//     <i className={'fa fa-trash-o fa-lg'} aria-hidden="true" />*/}
//         {/*//   </button>*/}
//         {/*//   <button style={styles.maximizeButton} onMouseOver={() => this.setState({ onButtonHover: true })} onMouseOut={() => this.setState({onButtonHover: false})} onClick={this.maximizeItem.bind(this)}>*/}
//         {/*//     <i className={'fa fa-window-restore'} aria-hidden="true" />*/}
//         {/*//   </button>*/}
//         {/*//   <i className={`fa ${getIconNameFromItemType(this.props.item.type)} fa-3x fa-inverse`} aria-hidden="true" />*/}
//         {/*//   {this.props.item.type === 'LINK' ?*/}
//         {/*//     <input style={styles.textField} defaultValue={this.props.item.linkUrl} onBlur={(e) => this.linkChanged(e.target.value)} type="text" placeholder={'insert link'} />*/}
//         {/*//     : null*/}
//         {/*//   }*/}
//         {/*//   {this.props.item.type === 'VIDEO' ?*/}
//         {/*//     <div style={styles.checkBoxContainer}>*/}
//         {/*//       <label htmlFor="fullScreenCheckedit">Fullscreen</label>*/}
//         {/*//       <input style={styles.checkBox} id="fullScreenCheckedit" type="checkbox" checked={this.props.item.isFullscreen} onChange={(e) => this.isFullScreenChanged(e.target.checked)} />*/}
//         {/*//     </div>*/}
//         {/*//     : null*/}
//         {/*//*/}
//         {/*//   }*/}
//         {/*//   {contentView}*/}
//         {/*//   {this.state.error ? <p style={styles.error}>{this.state.error}</p> : null}*/}
//         {/*//   <ConfirmDialog isOpen={this.state.confirmOpen} title="Confirm Delete" message="Are you sure you want to remove this item?" okTitle="Remove" onCancelClick={() => this.setState({ confirmOpen: false })} onConfirmClick={() => this.props.removeItem(this.props.item)} />*/}
//         {/*// </Dropzone>*/}
//         {/*<TargetItemToolbar />*/}
//       </Rnd>
//     );
//   }
//
//   getVideoDimensionsOf(url) {
//     return new Promise(function (resolve) {
//       let video = document.createElement('video');
//       video.addEventListener("loadedmetadata", function () {
//         let height = this.videoHeight;
//         let width = this.videoWidth;
//         resolve({
//           height: height,
//           width: width
//         });
//       }, false);
//       video.src = url;
//     });
//   }
//
//   getClosest(num, arr) {
//     let curr = arr[0];
//     let diff = Math.abs(num - curr);
//     for (let val = 0; val < arr.length; val++) {
//       let newDiff = Math.abs(num - arr[val]);
//       if (newDiff < diff) {
//         diff = newDiff;
//         curr = arr[val];
//       }
//     }
//     return curr;
//   }
// }

export default TargetItem;

TargetItem.propTypes = {
  // item: PropTypes.shape({
  //   id: PropTypes.string.isRequired,
  //   type: PropTypes.oneOf(['IMAGE', 'VIDEO', 'AUDIO', 'LINK']),
  //   isFullscreen: PropTypes.bool.isRequired,
  //   name: PropTypes.string.isRequired,
  //   url: PropTypes.string.isRequired,
  //   linkUrl: PropTypes.string,
  //   height: PropTypes.number.isRequired,
  //   width: PropTypes.number.isRequired,
  //   targetHeight: PropTypes.number.isRequired,
  //   targetWidth: PropTypes.number.isRequired,
  //   x: PropTypes.number.isRequired,
  //   y: PropTypes.number.isRequired,
  //   z: PropTypes.number.isRequired,
  //   lastModified: Date,
  // }).isRequired,
  updateItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  selectItem: PropTypes.func.isRequired,
  scaleFactor: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  originalImageSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
};
