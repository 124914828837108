import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { WEBSITE_URL } from '../config';

const Copyright = () => (
  <Typography variant="body2" color="inherit" align="center">
    {'Copyright © '}
    <Link color="inherit" href={WEBSITE_URL}>
      visionar GmbH
    </Link>
    {' '}
    {new Date().getFullYear()}
  </Typography>
);

export default Copyright;
