import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from '@mui/material/useMediaQuery';
import backgroundImage from '../../images/background.png';
import visionarLogo from '../../images/visionar_logo_2021_white.svg';
import Copyright from '../copyright';
import { useGetMeQuery } from '../../services/visionarRTK';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '100vh',
    paddingTop: theme.spacing(10),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    backgroundColor: 'white',
    marginTop: theme.spacing(6),
    padding: theme.spacing(8),
    boxShadow: '0px 4px 24px rgb(0 0 0 / 10%)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      margin: theme.spacing(1),
      padding: theme.spacing(4),
      width: 'auto',
      boxShadow: 'none',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBar: {
    boxShadow: 'none',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  logo: {
    width: '187px',
    maxWidth: '187px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      maxWidth: '150px',
    },
  },
  signUpButton: {
    marginLeft: theme.spacing(2),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  body: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    height: '50px',
    maxWidth: '100%',
    color: 'rgba(255, 255, 255, 0.7)',
  },
  button: {
    margin: theme.spacing(4, 0, 2),
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
}));

const PurchaseSuccess = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { refetch } = useGetMeQuery();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Toolbar>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <img src={visionarLogo} alt="logo" className={classes.logo} />
            </Grid>
            {
              matches
                ? (
                  <Grid item>
                    <Typography variant="body1" display="inline">Don&apos;t have an account?</Typography>
                    <Button
                      color="inherit"
                      className={classes.signUpButton}
                      component={RouterLink}
                      variant="outlined"
                      to="signup#account-setup"
                    >
                      SIGN UP NOW
                    </Button>
                  </Grid>
                )
                : (
                  <Button
                    color="inherit"
                    className={classes.signUpButton}
                    component={RouterLink}
                    size="small"
                    variant="outlined"
                    to="signup#account-setup"
                  >
                    SIGN UP
                  </Button>
                )
            }
          </Grid>
        </Toolbar>
      </AppBar>

      <div>
        <Container component="main" maxWidth="xs" className={classes.container}>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <CheckIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Thank you!</Typography>
            <Typography variant="body1" className={classes.body}>
              You should receive an order confirmation email
              shortly.
            </Typography>
            <Button
              color="primary"
              className={classes.button}
              fullWidth
              component={RouterLink}
              variant="contained"
              to="/"
            >
              Continue
            </Button>
          </div>
        </Container>
      </div>

      <Container xs={12} className={classes.footer}>
        <Copyright />
      </Container>
    </div>
  );
};

export default PurchaseSuccess;
