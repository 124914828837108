import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';
import SvgIcon from '@mui/material/SvgIcon';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import VideoCameraBackOutlinedIcon from '@mui/icons-material/VideoCameraBackOutlined';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import ComponentCard from '../../containers/component-card';
import { ReactComponent as VimeoIcon } from '../../icons/vimeo.svg';
import { ReactComponent as YouTubeIcon } from '../../icons/youtube.svg';
import { TargetType } from '../../models/target-item';

type ComponentSidebarProps = {
  isOpenComponents: boolean,
  closeButtonClicked: React.MouseEventHandler<HTMLButtonElement>,
  isDisabled: boolean
};

function ComponentSidebar({ isOpenComponents, closeButtonClicked, isDisabled }: ComponentSidebarProps) {
  const styles = {
    componentsSidebar: {
      position: 'fixed',
      width: isOpenComponents ? '303px' : '0',
      top: '64px',
      bottom: '0',
      right: '0',
      zIndex: 99,
      backgroundColor: 'common.white',
      transition: '0.5s',
      overflowX: 'hidden',
      boxShadow: '-1px 3px 3px #00000038',
    },
  };
  return (
    <Box sx={styles.componentsSidebar}>
      <Box sx={{
        width: '100%', height: '6px', backgroundColor: 'primary.main',
      }}
      />

      <Box sx={{
        display: 'flex', alignItems: 'center', height: '48px', pl: 1, borderBottom: `1px solid ${grey[200]}`,
      }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1, whiteSpace: 'nowrap' }}>Add Component</Typography>
        <IconButton aria-label="close" onClick={closeButtonClicked}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {/* <Grid item xs={6}> */}
        {/*  <ComponentCard */}
        {/*    isDisabled={isDisabled} */}
        {/*    icon={<TextFieldsOutlinedIcon sx={{ fontSize: '44px' }} />} */}
        {/*    title="Text" */}
        {/*    type={TargetType.Text} */}
        {/*  /> */}
        {/* </Grid> */}
        <Grid item xs={6}>
          <ComponentCard
            isDisabled={isDisabled}
            icon={<ImageOutlinedIcon sx={{ fontSize: '44px' }} />}
            title="Image"
            type={TargetType.Image}
          />
        </Grid>
        <Grid item xs={6}>
          <ComponentCard
            isDisabled={isDisabled}
            icon={<AudiotrackIcon sx={{ fontSize: '44px' }} />}
            title="Audio"
            type={TargetType.Audio}
          />
        </Grid>
        {/* <Grid item xs={6}> */}
        {/*  <ComponentCard */}
        {/*    isDisabled={isDisabled} */}
        {/*    icon={<CollectionsOutlinedIcon sx={{ fontSize: '44px' }} />} */}
        {/*    title="Image Gallery" */}
        {/*    type={TargetType.ImageGallery} */}
        {/*  /> */}
        {/* </Grid> */}
        <Grid item xs={6}>
          <ComponentCard
            isDisabled={isDisabled}
            icon={<LinkOutlinedIcon sx={{ fontSize: '44px' }} />}
            title="Hyperlink"
            type={TargetType.Link}
          />
        </Grid>
        <Grid item xs={6}>
          <ComponentCard
            isDisabled={isDisabled}
            icon={<PlayCircleFilledWhiteOutlinedIcon sx={{ fontSize: '44px' }} />}
            title="Video"
            type={TargetType.Video}
          />
        </Grid>
        <Grid item xs={6}>
          <ComponentCard
            isDisabled={isDisabled}
            icon={<VideoCameraBackOutlinedIcon sx={{ fontSize: '44px' }} />}
            title="Green Screen"
            type={TargetType.GreenScreen}
          />
        </Grid>
        {/* <Grid item xs={6}> */}
        {/*  <ComponentCard */}
        {/*    isDisabled={isDisabled} */}
        {/*    icon={<SvgIcon component={YouTubeIcon} inheritViewBox sx={{ fontSize: '44px' }} />} */}
        {/*    title="YouTube" */}
        {/*    type={TargetType.YouTube} */}
        {/*  /> */}
        {/* </Grid> */}
        {/* <Grid item xs={6}> */}
        {/*  <ComponentCard */}
        {/*    isDisabled={isDisabled} */}
        {/*    icon={<SvgIcon component={VimeoIcon} inheritViewBox sx={{ fontSize: '44px' }} />} */}
        {/*    title="Vimeo" */}
        {/*    type={TargetType.Vimeo} */}
        {/*  /> */}
        {/* </Grid> */}
      </Grid>
    </Box>
  );
}

export default ComponentSidebar;
