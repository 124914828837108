import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { useDropzone } from 'react-dropzone';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EditorAppBar from '../editor/editor-app-bar';
import ComponentSidebar from '../editor/component-sidebar';
import TargetSidebar from '../editor/target-sidebar';
// @ts-ignore
import ProgressView from '../../containers/progress-view';
// @ts-ignore
import TutorialWizard from '../../containers/tutorial-wizard';
// @ts-ignore
import { useGetProjectQuery } from '../../services/visionarRTK';
// @ts-ignore
import Editor from '../../containers/editor';
import PreviewSidebar from '../editor/preview-sidebar';

type EditorPageProps = {
  uploadTargets: Function
};
type EditorParams = {
  id: string;
};

function EditorPage({ uploadTargets }: EditorPageProps) {
  const history = useHistory();
  const { id } = useParams<EditorParams>();
  const [preview, setPreview] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isOpenComponents, setIsOpenComponents] = useState(false);
  const projectQuery = useGetProjectQuery(id);

  function onDropAccepted(files: Array<File>) {
    uploadTargets(id, files);
  }

  function onDropRejected() {
    setError('Some targets were not uploaded: Max upload size is 10MB per target.');
  }

  const {
    getInputProps, open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/png',
    multiple: true,
    maxSize: 2097152 * 5,
    onDropAccepted: (files) => onDropAccepted(files),
    onDropRejected: () => onDropRejected(),
  });

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  return (
    <div>
      <input {...getInputProps()} />
      <EditorAppBar
        previewMode={preview}
        projectName={projectQuery.isLoading ? '' : projectQuery.data.name}
        previewButtonClick={() => setPreview(!preview)}
        exitButtonClick={() => history.push('/')}
        addTargetButtonClick={open}
        addComponentButtonClick={() => setIsOpenComponents(!isOpenComponents)}
      />
      <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)', backgroundColor: grey[200] }}>
        <TargetSidebar projectId={id} />
        <Editor previewMode={preview} />
        <PreviewSidebar isOpen={preview} project={projectQuery.data} />
      </Box>
      <ComponentSidebar
        isOpenComponents={isOpenComponents && !preview}
        closeButtonClicked={() => setIsOpenComponents(!isOpenComponents)}
        isDisabled={false}
      />
      <Snackbar open={error != null} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <TutorialWizard isFirstLogin={!localStorage.getItem('tutorialShown')} />
      <ProgressView />
    </div>
  );
}

export default EditorPage;
