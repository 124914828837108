import {getCurrentUser, getShowTutorial} from "../selectors/setting";
import {connect} from "react-redux";
import TutorialWizard from "../components/tutorial-wizard";
import {toggleShowTutorial, updateUser} from "../actions/app";

const mapStateToProps = state => ({
  showTutorial: getShowTutorial(state),
  user: getCurrentUser(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleShowTutorial: () => {dispatch(toggleShowTutorial())},
  updateUser: (user) => {dispatch(updateUser(user))}
});

export default connect(mapStateToProps, mapDispatchToProps) (TutorialWizard)