// @flow

import { connect } from 'react-redux';

import { loadTargets, uploadTargets, deleteTarget } from '../actions/target';
import TargetList from '../components/target-list';
import { getTargets } from '../selectors/target';
import {setError} from "../actions/app";

const mapStateToProps = (state, props) => ({
  targets: getTargets(state),
  selectedProject: props.selectedProject,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadTargets: (projectId) => { dispatch(loadTargets(projectId)); },
  uploadTargets: (projectId, files) => { dispatch(uploadTargets(projectId, files)); },
  deleteTarget: (targetId) => { dispatch(deleteTarget(targetId)); },
  setError: (error) => { dispatch(setError(error)); }
});

// flow-disable-next-line
export default connect(mapStateToProps, mapDispatchToProps)(TargetList);
