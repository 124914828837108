import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { grey } from '@mui/material/colors';
import { TargetType } from '../../models/target-item';

export type ComponentCardProps = {
  isDisabled: boolean,
  icon: React.ReactNode,
  title: string,
  handleClick?: React.MouseEventHandler<HTMLDivElement>,
  type: TargetType
};

function ComponentCard({
  isDisabled, icon, title, handleClick,
}: ComponentCardProps) {
  const styles = {
    paperBase: {
      width: '128px',
      height: '128px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      pt: 4,
      pb: 1,
      border: `1px solid ${grey[200]}`,
    } as const,
    paperEnabled: {
      '&:hover': {
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
      },
    } as const,
    paperDisabled: {
      background: grey[200],
      color: grey[400],
    },
  };
  return (
    <Paper
      onClick={isDisabled ? () => null : handleClick}
      sx={isDisabled
        ? { ...styles.paperBase, ...styles.paperDisabled }
        : { ...styles.paperBase, ...styles.paperEnabled }}
    >
      <Box sx={{ flexGrow: 1 }}>
        {icon}
      </Box>
      <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>{title}</Typography>
    </Paper>
  );
}

export default ComponentCard;
