import { connect } from 'react-redux';

import { Dispatch } from 'redux';
import ComponentCard, { ComponentCardProps } from '../components/editor/component-card';
// @ts-ignore
import { addItem } from '../actions/content';

// @ts-ignore
const mapStateToProps = (state, props: ComponentCardProps) => ({
  isDisabled: props.isDisabled,
  icon: props.icon,
  title: props.title,
});

const mapDispatchToProps = (dispatch: Dispatch, props: ComponentCardProps) => ({
  handleClick: () => { dispatch(addItem(props.type)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentCard);
