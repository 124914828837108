import Radium from "radium";
import React from "react";
import {Modal, ModalBody} from "reactstrap";
import DialogCloseButton from "./dialog-close-button";
import DialogHeader from "./dialog-header";

const styles = {
  title: {
    color: 'var(--primaryColor)',
    width: '100%',
    textAlign: 'center',
    marginTop: '50px'
  },
  modal: {
    minWidth: '750px'
  },
  textArea: {
    marginTop: '30px',
    marginBottom: '70px'
  },
  saveButton: {
    width: '150px',
    color: 'white',
    height: '40px',
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    border: '0',
    borderRadius: '0',
    backgroundColor: 'var(--primaryColor)',
    ":hover": {
      backgroundColor: 'var(--primaryHover)',
      cursor: 'pointer'
    }
  },
  saveButtonDisabled: {
    width: '150px',
    color: 'white',
    height: '40px',
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    border: '0',
    borderRadius: '0',
    outline: 'none',
    backgroundColor: '#989898'
  },
}

export class ProjectKey extends React.Component {

  state = {
    productKey: undefined,
  }

  componentDidUpdate() {
    if (this.props.secretKeyVerified && this.state.productKey) {
      this.saveProjectKey();
    }
  }

  handleProjectKeyChange = (e) => {
    if (!e) {
      return;
    }
    this.props.resetSecretKeyVerified();
    if (!e.target || !e.target.value) {
      this.setState({productKey: undefined});
      return;
    }

    this.setState({productKey: e.target.value.toString().trim()});
  };

  saveProjectKey() {
    localStorage.setItem('project-key', this.state.productKey);
    this.props.onKeySave();
  }

  verifySecretKey() {
    this.props.verifySecretKey(sessionStorage.getItem('user'), this.state.productKey);
  }

  close() {
    this.props.onClose();
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} style={styles.modal}>
          <DialogCloseButton handleClick={this.close.bind(this)}/>
          <DialogHeader/>
          <ModalBody>
            <h2 style={styles.title}>Please insert your secret key</h2>

            <textarea disabled={false}
                      type="text"
                      value={this.state.projectKey}
                      onChange={this.handleProjectKeyChange}
                      style={styles.textArea}
                      className="form-control"
                      id="keyInput"
            />
            <button style={this.state.productKey ? styles.saveButton : styles.saveButtonDisabled} onClick={this.verifySecretKey.bind(this)}>
              SAVE
            </button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Radium(ProjectKey);
