import React from 'react';
import Radium from 'radium';

const styles = {
  primary: {
    borderRadius: '25px',
    letterSpacing: '0.3em',
    textTransform: 'uppercase',
    fontSize: '12px',
    height: '36px',
    backgroundColor: 'var(--primaryColor)',
    color: 'var(--text)',
    padding: '0px 16px 0px 16px',
    display: 'block',
    ':hover': {
      backgroundColor: 'var(--primaryHover)',
    },
    ':focus': {
      boxShadow: 'none',
    },
  },
  secondary: {
    borderRadius: '25px',
    letterSpacing: '0.3em',
    textTransform: 'uppercase',
    fontSize: '12px',
    height: '36px',
    backgroundColor: 'white',
    borderColor: 'var(--primaryColor)',
    color: 'var(--primaryColor)',
    padding: '0px 16px 0px 16px',
    ':hover': {
      backgroundColor: 'var(--primaryColor)',
      color: 'var(--text)',
    },
    ':focus': {
      boxShadow: 'none',
    },
  },
};

const Button = ({
  icon, label, handleClick, flipIcon, width, shadow, secondary, style,
}) => (
  <button
    onClick={handleClick}
    className="btn"
    type="button"
    style={
      secondary
        ? { ...styles.secondary, width, boxShadow: shadow ? '1px 3px 20px #1C68FF96' : 'none' }
        : [{ ...styles.primary, width, boxShadow: shadow ? '1px 3px 20px #1C68FF96' : 'none' }, style]
    }
  >
    <span
      className={`fa ${icon}`}
      aria-hidden="true"
      style={{
        verticalAlign: 'middle',
        marginBottom: '2px',
        marginRight: '8px',
        fontSize: '22px',
        transform: flipIcon ? 'scaleX(-1)' : 'scaleX(1)',
      }}
    />
    <span style={{ lineHeight: '24px' }}>{label}</span>
  </button>
);

export default Radium(Button);
