import React from "react";
import { Link, Redirect } from "react-router-dom";
import Radium from "radium";
import SetupWizard from "../containers/setup-wizard";
import { getProjectKey } from "../auth";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import { addHours, getTimeDiffrence } from "../utils";
import ProjectKey from "../containers/project-key";
import { WEBSITE_URL } from '../config';

const styles = {
  card: {
    width: '300px',
    height: '200px',
    margin: '12.5px',
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid var(--dividerColor)',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
  },
  cardTitle: {
    fontSize: '16px',
    marginBottom: '3px',
  },
  cardSubTitle: {
    fontSize: '14px',
    color: 'var(--secondaryText)'
  },
  statusActive: {
    color: 'var(--primaryColor)',
    letterSpacing: '2.2px',
    fontSize: '12px',
  },
  statusInactive: {
    color: '#F44336',
    fontSize: '12px',
    letterSpacing: '2.2px',
  },
  statusPending: {
    color: '#FFC107',
    fontSize: '12px',
    letterSpacing: '2.2px',
  },
  footer: {
    backgroundColor: 'transparent',
    border: '0px',
    fontSize: '12px',
    color: 'var(--secondaryText)'
  },
  newLicenseLink: {
    color: 'var(--primaryColor)',
    textAlign: 'center',
    textDecoration: 'none',
  },
  newLicenseIcon: {
    paddingTop: '16px'
  }
}

class ProjectItem extends React.Component {
  state = {
    toEditor: false,
    showNewProjectDialog: false,
    projectKey: getProjectKey(),
    showProjectKeyDialog: false,
    hours: '0',
    minutes: '0',
    seconds: '0',
    redirect: false,
    redirectTarget: ''
  }

  constructor() {
    super();
    this.getRandomColor = this.getRandomColor.bind(this);
    this.onProjectKeySave = this.onProjectKeySave.bind(this);
    this.onProjectKeyModalCancel = this.onProjectKeyModalCancel.bind(this);
  }

  interval;

  componentDidMount() {
    // this.updateTime();
    // this.interval = setInterval(function () {
    //   if (this.props && this.props.item && this.props.item.lastCleanUpDate) {
    //     this.lastCleanUpDate = addHours(this.props.item.lastCleanUpDate, 48);
    //     this.updateTime();
    //   }
    // }.bind(this), 1000);

    // if (this.props.initialClick && this.props.item) {
    //   this.handleClick();
    // }
  }

  updateTime() {
    let diffrence = getTimeDiffrence(this.lastCleanUpDate);
    if (diffrence.milliseconds >= 0) {
      this.setState({
        hours: diffrence.hours < 10 ? `0${diffrence.hours}` : diffrence.hours,
        minutes: diffrence.minutes < 10 ? `0${diffrence.minutes}` : diffrence.minutes,
        seconds: diffrence.seconds < 10 ? `0${diffrence.seconds}` : diffrence.seconds
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getRandomColor() {
    const color = localStorage.getItem(`${this.props.item.id}_color`);
    if (color) {
      return color;
    }

    var length = 6;
    var chars = '0123456789ABCDEF';
    var hex = '#';
    while (length--) hex += chars[(Math.random() * 16) | 0];
    localStorage.setItem(`${this.props.item.id}_color`, hex);
    return hex;
  }

  onProjectKeySave() {
    // this.setState({ projectKey: getProjectKey() }, () => {
    //   this.setState({ showProjectKeyDialog: false });
    //   this.handleClick();
    // });
  }

  handleClick() {
    this.props.selectProject(this.props.item.id);
    this.setState({ 'toEditor': true });
  }

  onProjectKeyModalCancel() {
    this.setState({ 'showProjectKeyDialog': false });
  }

  render() {

    if (this.state.redirect) {
      window.location.href = this.state.redirectTarget;
    }

    if (this.props.item && this.props.item.lastCleanUpDate && this.props.item.isDemo) {
      let date = new Date(this.props.item.lastCleanUpDate);
      date.setHours(date.getHours() + 48);
      this.lastCleanUpDate = date;
    }

    if (this.state.toEditor === true) {
      const url = '/editor/' + this.props.item._id;
      return <Redirect to={url} push />
    }

    return <div>
      {
        this.props.item ?
          <div onClick={() => {
            this.handleClick()
          }}>
            <Card style={styles.card}>
              <CardBody>
                <Row>
                  <Col xs="8">
                    <span style={styles.cardTitle}><b>{this.props.item.name}</b></span>
                  </Col>
                  <Col xs="4" style={{ textAlign: 'right', paddingLeft: '0', paddingRight: '8px' }}>
                    <span
                      style={styles.statusActive}>{this.props.item.subscriptionStatus}</span>
                  </Col>
                </Row>
                <span style={styles.cardSubTitle}>{this.props.item.licenseInfo}</span>
              </CardBody>
              <CardFooter style={styles.footer}>
                <Row>
                  <Col>
                    <div style={{ float: 'right' }}>
                      {
                        !this.props.item.isDemo ?
                          <table>
                            <tbody>
                              <tr>
                                <td><i className="fa fa-camera"></i></td>
                                <td style={{ textAlign: 'right', paddingLeft: '3px' }}>{this.props.item.targetsCount}</td>
                              </tr>
                              {/* <tr>
                                <td><i className="fa fa-database"></i></td>
                                <td style={{ textAlign: 'right' }}>{this.props.item.usedStorage}</td>
                                <td>/</td>
                                <td>{this.props.item.maxStorage}</td>
                              </tr> */}
                            </tbody>
                          </table>
                          : <p style={{ height: '100%', marginBottom: '0px', marginTop: '18px', color: 'red' }}>
                            <i className="fa fa-trash-o"></i>{` ${this.state.hours}:${this.state.minutes}:${this.state.seconds} `}</p>
                      }
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
            <ProjectKey isOpen={this.state.showProjectKeyDialog} onKeySave={this.onProjectKeySave} onClose={this.onProjectKeyModalCancel} />
          </div>
          :
          <Link to="checkout" style={styles.newLicenseLink}>
            <Card style={styles.card}>
              <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span style={styles.newLicenseLink}>
                  <i className="fa fa-plus fa-2x"></i>
                  <p style={styles.newLicenseIcon} className="btn-text">Purchase License</p>
                </span>
              </div>
            </Card>
          </Link>
      }

    </div>
  }
}

export default Radium(ProjectItem);
