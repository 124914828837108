export const isProd = process.env.NODE_ENV === 'production';
export const BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER || 'http://localhost:8000';
// export const BACKEND_SERVER = 'https://api.visionarstaging.com' // STAGING
export const SOCKET_SERVER = process.env.NODE_ENV === 'production' ? 'https://api.visionar.com:8001' : 'http://localhost:8001';
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL || 'https://www.visionarstaging.com';
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY || 'pk_test_51IL2jzHPhv8ULW9FwQ9XbUxUVSnVwCsGeADtkKjalMp79TSmqJytVDoBGNKX36RGxFQN3htgDFRj0Gmcu1dFYUvj00k5HzCl8Q';
export const STRIPE_ENVIRONMENT = process.env.REACT_APP_STRIPE_ENVIRONMENT || 'test';
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const STRIPE_PRICE_IDS = {
  test: {
    basicMonthly: 'price_1IL42fHPhv8ULW9F0hLwAIfP',
    basicYearly: 'price_1IL42fHPhv8ULW9FSErBkBe4',
    standardMonthly: 'price_1IL47QHPhv8ULW9FyIQ0tFka',
    standardYearly: 'price_1IL47QHPhv8ULW9Fhu67ojm2', 
    premiumMonthly: 'price_1IL4NrHPhv8ULW9FZYwAif6X',
    premiumYearly: 'price_1IL4NrHPhv8ULW9FrJNWJnif',
  },
  live: {
    basicMonthly: 'price_1JPOogHPhv8ULW9Fze3EIlxF',
    basicYearly: 'price_1JPOogHPhv8ULW9FZoL6iBoc',
    standardMonthly: 'price_1JPOq8HPhv8ULW9F55LdCUiI',
    standardYearly: 'price_1JPOq8HPhv8ULW9FLCX20sz9',
    premiumMonthly: 'price_1JPOqEHPhv8ULW9FDM1sxbuH',
    premiumYearly: 'price_1JPOqEHPhv8ULW9FKNMcf2Uy',
  },
};

export function getStripePriceIds() {
  if (STRIPE_ENVIRONMENT === 'live') {
    return STRIPE_PRICE_IDS.live;
  }

  return STRIPE_PRICE_IDS.test;
}

export function getStripePriceId(plan, isYearly) {
  const priceIds = getStripePriceIds();
  switch (plan) {
    case 1:
      return isYearly ? priceIds.basicYearly : priceIds.basicMonthly;
    case 2:
      return isYearly ? priceIds.standardYearly : priceIds.standardMonthly;
    case 3:
      return isYearly ? priceIds.premiumYearly : priceIds.premiumMonthly;
    default:
      return priceIds.standardMonthly;
  }
}
