// @flow

import React from 'react';
import Radium from 'radium';
import { BACKEND_SERVER } from '../config';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';import { Tooltip, Box, Typography } from '@mui/material';
import EmojiSatisfied from '../images/emoji_satisfied.svg';
import EmojiNeutral from '../images/emoji_neutral.svg';
import EmojiUnsatisfied from '../images/emoji_unsatisfied.svg';

const styles = {
  target: {
    margin: '8px',
    border: '3px solid var(--dividerColor)',
    backgroundColor: '#ebeff2',
    width: '126px',
    height: '130px',
    borderRadius: '8px',
    outline: '0',
    ':focus': {
      outline: '0',
      boxShadow: '0',
    },
  },
  selectedTarget: {
    margin: '8px',
    backgroundColor: '#ebeff2',
    border: '3px solid var(--primaryColor)',
    borderRadius: '8px',
    width: '126px',
    height: '130px',
    outline: '0',
    boxShadow: '1px 3px 20px #1C68FF96',
    ':focus': {
      outline: '0',
      boxShadow: '1px 3px 20px #1C68FF96',
    },
  },
  image: {
    width: '100%',
    height: 'calc(100% - 20px)',
    objectFit: 'cover',
    borderRadius: '5px 5px 0 0',
  },
  deleteButton: {
    float: 'right',
    border: '0',
    borderRadius: '0 0 5px 0',
    fontSize: '14px',
    background: 'transparent',
  },
  label: {
    margin: '0',
    textAlign: 'center',
    width: '120px',
    lineHeight: '20px',
    paddingLeft: '45%',
    color: 'var(--primaryText)',
    fontSize: '14px',
  },
  labelActive: {
    margin: '0',
    textAlign: 'center',
    fontSize: '14px',
    width: '120px',
    fontWeight: '600',
    lineHeight: '20px',
    paddingLeft: '45%',
    color: 'var(--primaryColor)',
  },
};

class TargetView extends React.Component {
  showToolTip = false;

  constructor() {
    super();
    this.state = {
      status: '',
      targetId: '',
      physicalWidth: 0,
    };
  }

  componentDidMount() {
    this.setState({ status: this.props.target.vuforiaStatus });
    this.setState({ targetId: this.props.target.id });
    if (this.props.target.physicalWidth) {
      this.setState({ physicalWidth: this.props.target.physicalWidth });
    } else {
      const img = new Image();
      img.src = BACKEND_SERVER + this.props.target.filePath;
      img.onload = () => {
        const width = this.convertPixelToCm(img.width).toFixed(0);
        this.setState({ physicalWidth: width });
        this.save(width);
      };
    }
  }

  componentWillUnmount() {
  }

  handlePhysicalWidthChanged = (e) => {
    if (e.target.value < 0 || e.target.value > 3000) {
      return;
    }
    this.setState({ physicalWidth: e.target.value || 0 });
    this.save(e.target.value);
  };

  save(width) {
    const newTarget = { ...this.props.target };
    newTarget.physicalWidth = width;
    this.props.updateTarget(newTarget);
  }

  convertPixelToCm(pixel) {
    if (!pixel) {
      return 0;
    }
    const onePixelInCm = 0.0264583333;
    return pixel * onePixelInCm;
  }

  renderQualityEmoji() {

    if (!this.props.target.quality) {
      return <Box style={{ width: '32px', height: '32px', position: 'relative', bottom: '55%', left: '35%', borderRadius: '50%'}}/>
    }

    if (this.props.target.quality >= 75) {
      return (
        <Tooltip title={
          <React.Fragment>
            <Typography variant='subtitle1'>Image Quality {this.props.target.quality}/100: <span style={{ color: '#00c41c', fontWeight: 'bold' }}>Good</span></Typography>
            <Typography variant="body2">The image should be easily detected and provide stable tracking. </Typography>
          </React.Fragment>
        } placement="top" arrow>
          <img src={EmojiSatisfied} style={{ width: '32px', height: '32px', position: 'relative', bottom: '55%', left: '35%', borderRadius: '50%', border: '1px solid black', boxShadow: '0px 0px 8px 2px rgba(0,0,0,0.45)' }} />
        </Tooltip>
      );
    } else if (this.props.target.quality >= 51) {
      return (
        <Tooltip title={
          <React.Fragment>
            <Typography variant='subtitle1'>Image Quality {this.props.target.quality}/100: <span style={{ color: '#FCD12B', fontWeight: 'bold' }}>Medium</span></Typography>
            <Typography variant="body2">The image qualiy is good enough, but could be harder to detect and keep track of. </Typography>
          </React.Fragment>
        } placement="top" arrow>
          <img src={EmojiNeutral} style={{ width: '32px', height: '32px', position: 'relative', bottom: '55%', left: '35%', borderRadius: '50%', border: '1px solid black', boxShadow: '0px 0px 8px 2px rgba(0,0,0,0.45)' }} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={
          <React.Fragment>
            <Typography variant='subtitle1'>Image Quality {this.props.target.quality}/100: <span style={{ color: '#EF171B', fontWeight: 'bold' }}>Bad</span></Typography>
            <Typography variant="body2">The image might not be detected. Please upload a better image. </Typography>
          </React.Fragment>
        } placement="top" arrow>
          <img src={EmojiUnsatisfied} style={{ width: '32px', height: '32px', position: 'relative', bottom: '55%', left: '35%', borderRadius: '50%', border: '1px solid black', boxShadow: '0px 0px 8px 2px rgba(0,0,0,0.45)' }} />
        </Tooltip>
      );
    }
  }

  render() {
    return (
      <div
        key="targetDiv"
        role="button"
        tabIndex={0}
        style={this.props.isSelected ? styles.selectedTarget : styles.target}
        onClick={this.props.selectTarget}
      >
        <img id="targetImg" style={styles.image} alt="target" src={this.props.target.filePath} />
        {this.renderQualityEmoji()}
        <Box sx={{width: '100%', height: '20px', position: 'relative', bottom: '34px'}}>
        <span style={this.props.isSelected ? styles.labelActive : styles.label}>{this.props.indexNumber}</span>
        <button style={styles.deleteButton} onClick={this.props.removeTarget}>
          <DeleteOutlineIcon fontSize='small' />
        </button>
        </Box>
      </div>
    );
  }
}

export default Radium(TargetView);
