import {connect} from "react-redux";
import SetupWizard from "../components/setup-wizard";
import {getKeyFile, saveAppKey, updateProject, verifyVuforiaKeys, selectProject, setHasSingleNewProject} from "../actions/project";
import {getIsVuforiaKeyVerifyRunning, getVuforiaKeysVerified} from "../selectors/project";
import {loadUser} from "../actions/app";
import {getCurrentUser} from "../selectors/setting";

const mapStateToProps = (state, props) => ({
  isOpen: props.isOpen,
  item: props.item,
  isVuforiaKeyVerifyRunning: getIsVuforiaKeyVerifyRunning(state),
  vuforiaKeysVerified: getVuforiaKeysVerified(state),
  user: getCurrentUser(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateProject: (project) => { dispatch(updateProject(project)); },
  getKeyFile: (project, productKey) => { dispatch(getKeyFile(project, productKey))},
  verifyKeys: (project) => { dispatch(verifyVuforiaKeys(project))},
  loadUser: (id) => { dispatch(loadUser(id)); },
  saveAppKey: (projectId, appKey) => {dispatch(saveAppKey(projectId, appKey))},
  selectProject: (id) => { dispatch(selectProject(id)); },
  setHasSingleNewProject: (value) => { dispatch(setHasSingleNewProject(value)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupWizard)
