import backend from './services/visionar';

export function getToken() {
  return sessionStorage.getItem('access-token');
}

export async function isAuthenticated(currentUser, loadUserSuccess) {
  const token = getToken();
  const user = currentUser;

  if (token && user) {
    return true;
  }

  if (token) {
    const user = await getMe();
    if (user) {
      loadUserSuccess(user);
      return true;
    }
  }

  return false;
}

export async function login(email, password) {
  const res = await backend.loginUser(email, password);
  if (res.token && res.id) {
    sessionStorage.setItem('access-token', res.token);
    return res;
  }

  return null;
}

export async function register(email, password) {
  const res = await backend.register(email, password);
  if (res.token && res.id) {
    sessionStorage.setItem('access-token', res.token);
    return res;
  }

  return null;
}

export async function getMe() {
  const res = await backend.getMe();
  if (res.token && res.id) {
    return res;
  }

  return null;
}

export function signout(cb) {
  sessionStorage.removeItem('access-token');
  setTimeout(cb, 100);
}

export function getProjectKey() {
  return localStorage.getItem('project-key');
}
