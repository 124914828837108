import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
import 'fontsource-roboto';
import {
  createTheme, ThemeProvider as MuiThemeProvider, StyledEngineProvider, adaptV4Theme,
} from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import App from './containers/app';
import targetReducer from './reducers/target';
import { isProd } from './config';
import settingReducer from './reducers/setting';
import projectReducer from './reducers/project';
import paymentReducer from './reducers/payment';
import rawTheme from './theme.json';
import { visionarApi } from './services/visionarRTK';

const composeEnhancers = (isProd ? null : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const theme = createTheme(adaptV4Theme(rawTheme));

const appReducer = combineReducers({
  target: targetReducer,
  setting: settingReducer,
  project: projectReducer,
  payment: paymentReducer,
  [visionarApi.reducerPath]: visionarApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined;
  }

  return appReducer(state, action);
};

const rtkQueryErrorLogger = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const endpointName = action.meta.arg.endpointName;
    if (endpointName === 'login'
      || endpointName === 'register'
      || endpointName === 'signInWithGoogle'
      || endpointName === 'signUpWithGoogle') {
      return next(action);
    }

    const statusCode = action.payload.status;
    if (statusCode === 401) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/login';
    } else if (statusCode === 451) {
      window.location.href = '/signup#privacy';
    } else if (statusCode === 402) {
      window.location.href = '/signup?plan=returning#welcome';
    }
  }

  return next(action);
};

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunkMiddleware, rtkQueryErrorLogger, visionarApi.middleware),
));

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root'),
);
