import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { loadStripe } from '@stripe/stripe-js';
import { useCreateStripeCheckoutSessionMutation, useGetMeQuery } from '../../services/visionarRTK';
import { getStripePriceId, STRIPE_API_KEY, WEBSITE_URL } from '../../config';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    marginTop: theme.spacing(6),
    padding: theme.spacing(8),
    boxShadow: '0px 4px 24px rgb(0 0 0 / 10%)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: 'auto',
      boxShadow: 'none',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  grid: {
    marginTop: theme.spacing(1),
  },
  checkIcon: {
    color: theme.palette.primary.main,
    width: '1.4em',
    height: '1.2em',
  },
  checks: {
    marginTop: theme.spacing(0.5),
  },
  body: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  planTitleCard: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    opacity: '0.6',
    cursor: 'pointer',
  },
  planTitleCardSelected: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },
  selected: {
    color: theme.palette.primary.main,
  },
  notSelectedText: {
    color: theme.palette.text.disabled,
  },
  switch: {
    marginTop: theme.spacing(3),
  },
  moreInformationLink: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      width: '100%',
    },
  },
  pricingTable: {
    tableLayout: 'fixed',
  },
  arrowDown: {
    width: '0',
    height: '0',
    margin: 'auto',
    position: 'relative',
    top: '-1px',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderTop: `15px solid ${theme.palette.primary.main}`,
  },
  desktopHeader: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileHeaderRow: {
    display: 'table-row',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  mobileHeader: {
    border: 'none',
    textAlign: 'center',
    paddingTop: '16px',
    paddingBottom: '0px',
  },
}));

const MonthlySwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.main,
  },
  checked: {},
  track: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Switch);

const SignUpFlowPricing = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const tableSize = matches ? 'small' : 'medium';
  const [isYearly, setIsYearly] = useState(false);
  const [plan, setPlan] = useState(2);
  const getMeResult = useGetMeQuery();
  const [createCheckoutSession, { isLoading }] = useCreateStripeCheckoutSessionMutation();
  const [error, setError] = useState(null);
  const [stripeLoading, setStripeLoading] = useState(false);
  const stripePromise = loadStripe(STRIPE_API_KEY);

  const toCheckout = async () => {
    try {
      setStripeLoading(true);
      const priceId = getStripePriceId(plan, isYearly);
      const email = getMeResult.data.email;
      const result = await createCheckoutSession({ priceId, email }).unwrap();
      if (!result.sessionId) {
        setError('unknown_error');
        return;
      }

      setStripeLoading(true);
      const stripe = await stripePromise;
      const stripeResult = await stripe.redirectToCheckout({
        sessionId: result.sessionId,
      });

      if (stripeResult.error) {
        setError(stripeResult.error.message);
      }
      setStripeLoading(false);
    } catch (err) {
      setError('unknown_error');
      setStripeLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  if (getMeResult.isLoading || !getMeResult.isSuccess) {
    return <CircularProgress style={{ color: 'white' }} size={24} />;
  }

  // if (getMeResult.isSuccess  && getMeResult.data.subscriptionStatus === 'active' || getMeResult.data.subscriptionStatus === 'trialing') {
  //   return <Redirect to="/" />;
  // }

  return (
    <Container component="main" maxWidth="md" className={classes.container}>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes.header}>
          Choose your
          plan
        </Typography>
        <Typography variant="body1" className={classes.body}>
          Cancel or downgrade your plan at any
          time. No hidden fees.
        </Typography>

        <Grid
          component="label"
          justifyContent="center"
          container
          alignItems="center"
          spacing={1}
          className={classes.switch}
        >
          <Grid item>
            <Typography
              variant="subtitle1"
              className={isYearly ? classes.notSelectedText : classes.selected}
            >
              Monthly Plans
            </Typography>
          </Grid>
          <Grid item>
            <MonthlySwitch
              checked={isYearly}
              onChange={(e) => setIsYearly(e.target.checked)}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              className={isYearly ? classes.selected : classes.notSelectedText}
            >
              Annual discount
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" className={classes.grid} spacing={2}>
          {
            !matches ? <Grid item xs={6} /> : null
          }
          <Grid item xs={matches ? 4 : 2}>
            <Paper
              elevation={0}
              className={plan === 1 ? classes.planTitleCardSelected : classes.planTitleCard}
              onClick={() => setPlan(1)}
            >
              <Typography variant="button">Basic</Typography>
            </Paper>
            {plan === 1 ? <div className={classes.arrowDown} /> : null}
          </Grid>
          <Grid item xs={matches ? 4 : 2}>
            <Paper
              elevation={0}
              className={plan === 2 ? classes.planTitleCardSelected : classes.planTitleCard}
              onClick={() => setPlan(2)}
            >
              <Typography variant="button">Standard</Typography>
            </Paper>
            {plan === 2 ? <div className={classes.arrowDown} /> : null}
          </Grid>
          <Grid item xs={matches ? 4 : 2}>
            <Paper
              elevation={0}
              className={plan === 3 ? classes.planTitleCardSelected : classes.planTitleCard}
              onClick={() => setPlan(3)}
            >
              <Typography variant="button">Premium</Typography>
            </Paper>
            {plan === 3 ? <div className={classes.arrowDown} /> : null}
          </Grid>
        </Grid>
        <Table size={tableSize} aria-label="pricing table" className={classes.pricingTable}>
          <TableBody>
            <TableRow key="priceMobileHeader" className={classes.mobileHeaderRow}>
              <TableCell component="th" scope="row" colSpan={3} className={classes.mobileHeader}>
                <Typography variant="body2">Monthly price</Typography>
              </TableCell>
            </TableRow>
            <TableRow key="price">
              <TableCell component="th" scope="row" className={classes.desktopHeader}>
                Monthly
                price
              </TableCell>
              <TableCell
                className={plan === 1 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(1)}
              >
                <Typography component="div" variant="body1">
                  <Box fontWeight="fontWeightBold">{isYearly ? '€42' : '€47'}</Box>
                </Typography>
              </TableCell>
              <TableCell
                className={plan === 2 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(2)}
              >
                <Typography component="div" variant="body1">
                  <Box fontWeight="fontWeightBold">{isYearly ? '€87' : '€97'}</Box>
                </Typography>
              </TableCell>
              <TableCell
                className={plan === 3 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(3)}
              >
                <Typography component="div" variant="body1">
                  <Box fontWeight="fontWeightBold">{isYearly ? '€267' : '€297'}</Box>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow key="targetsMobileHeader" className={classes.mobileHeaderRow}>
              <TableCell component="th" scope="row" colSpan={3} className={classes.mobileHeader}>
                <Typography variant="body2">Targets / Scenes</Typography>
              </TableCell>
            </TableRow>
            <TableRow key="targets">
              <TableCell component="th" scope="row" className={classes.desktopHeader}>
                Targets /
                Scenes
              </TableCell>
              <TableCell
                className={plan === 1 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(1)}
              >
                <Typography
                  component="div"
                  variant="body1"
                >
                  <Box fontWeight="fontWeightBold">10</Box>
                </Typography>
              </TableCell>
              <TableCell
                className={plan === 2 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(2)}
              >
                <Typography
                  component="div"
                  variant="body1"
                >
                  <Box fontWeight="fontWeightBold">25</Box>
                </Typography>
              </TableCell>
              <TableCell
                className={plan === 3 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(3)}
              >
                <Typography
                  component="div"
                  variant="body1"
                >
                  <Box fontWeight="fontWeightBold">100</Box>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow key="projectsMobileHeader" className={classes.mobileHeaderRow}>
              <TableCell component="th" scope="row" colSpan={3} className={classes.mobileHeader}>
                <Typography variant="body2">Unlimited projects</Typography>
              </TableCell>
            </TableRow>
            <TableRow key="projects">
              <TableCell component="th" scope="row" className={classes.desktopHeader}>
                Unlimited
                Projects
              </TableCell>
              <TableCell
                className={plan === 1 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(1)}
              >
                <CheckIcon />
              </TableCell>
              <TableCell
                className={plan === 2 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(2)}
              >
                <CheckIcon />
              </TableCell>
              <TableCell
                className={plan === 3 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(3)}
              >
                <CheckIcon />
              </TableCell>
            </TableRow>
            <TableRow key="mobileSDKMobileHeader" className={classes.mobileHeaderRow}>
              <TableCell component="th" scope="row" colSpan={3} className={classes.mobileHeader}>
                <Typography variant="body2">iOS & Android SDK</Typography>
              </TableCell>
            </TableRow>
            <TableRow key="mobileSDK">
              <TableCell component="th" scope="row" className={classes.desktopHeader}>
                Advanced Components
              </TableCell>
              <TableCell
                className={plan === 1 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(1)}
              >
                <RemoveIcon />
              </TableCell>
              <TableCell
                className={plan === 2 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(2)}
              >
                <CheckIcon />
              </TableCell>
              <TableCell
                className={plan === 3 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(3)}
              >
                <CheckIcon />
              </TableCell>
            </TableRow>
            <TableRow key="prioritySupportMobileHeader" className={classes.mobileHeaderRow}>
              <TableCell component="th" scope="row" colSpan={3} className={classes.mobileHeader}>
                <Typography variant="body2">Priority Support</Typography>
              </TableCell>
            </TableRow>
            <TableRow key="prioritySupport">
              <TableCell component="th" scope="row" className={classes.desktopHeader}>
                Priority
                Support
              </TableCell>
              <TableCell
                className={plan === 1 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(1)}
              >
                <RemoveIcon />
              </TableCell>
              <TableCell
                className={plan === 2 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(2)}
              >
                <RemoveIcon />
              </TableCell>
              <TableCell
                className={plan === 3 ? classes.selected : classes.notSelectedText}
                align="center"
                onClick={() => setPlan(3)}
              >
                <CheckIcon />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Link
          href={`${WEBSITE_URL}/pricing`}
          target="_blank"
          variant="caption"
          className={classes.moreInformationLink}
        >
          For more information visit our pricing page.
        </Link>
        <Button
          fullWidth
          className={classes.button}
          variant="contained"
          disabled={isLoading || !getMeResult.isSuccess || stripeLoading}
          color="primary"
          onClick={toCheckout}
        >
          {isLoading || stripeLoading ? <CircularProgress size={24} /> : <span>Continue</span>}
        </Button>
        <Snackbar open={error != null} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {
              error === 'unknown_error'
                ? (
                  <span>
                    An error occurred. Please try again or
                    <a href="mailto:support@visionar.com"> contact support</a>
                    .
                  </span>
                ) : <span>{error}</span>
            }
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

export default SignUpFlowPricing;
