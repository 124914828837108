import React from "react";
import {Modal, ModalBody} from "reactstrap";
import Radium from "radium";
import TutorialStep1 from "./tutorial-step1";
import TutorialStep2 from "./tutorial-step2";
import TutorialStep3 from "./tutorial-step3";
import DialogCloseButton from "./dialog-close-button";

const styles = {
  modal: {
    minWidth: '800px',
    height: '670px'
  },
}

export class TutorialWizard extends React.Component {
  state = {
    isFirstLogin: false,
    neverShowTips: true
  };

  constructor() {
    super();
    this.closeWizard = this.closeWizard.bind(this);
  }

  componentDidMount() {
    this.setState({isFirstLogin: this.props.isFirstLogin});
  }

  closeWizard() {
    if (!this.state.isFirstLogin) {
      this.props.toggleShowTutorial();
    }
    
    this.setState({isFirstLogin: false});
    if (this.state.neverShowTips) {
      localStorage.setItem('tutorialShown', true.toString());
    }
  }

  render() {
    var StepZilla = require('react-stepzilla-redux').default;
    const steps = [
      {name: 'Step1', component: <TutorialStep1 onClose={this.closeWizard}/>},
      {name: 'Step2', component: <TutorialStep2 onClose={this.closeWizard}/>},
      {name: 'Step3', component: <TutorialStep3 onClose={this.closeWizard}/>}
    ];

    return (
        <Modal isOpen={this.props.showTutorial || this.state.isFirstLogin} style={styles.modal}>
          <DialogCloseButton handleClick={this.closeWizard.bind(this)}/>
          <ModalBody>
            <div>
              <StepZilla steps={steps} showSteps={false}
                         showNavigation={false}/>
            </div>
          </ModalBody>
        </Modal>
    );
  }
}

export default Radium(TutorialWizard);