import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import SecurityIcon from '@mui/icons-material/SecurityOutlined';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { getStripePriceId, STRIPE_API_KEY, WEBSITE_URL } from '../../config';
import {
  useAcceptPrivacyMutation,
  useCreateStripeCheckoutSessionMutation,
  useGetMeQuery,
} from '../../services/visionarRTK';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    marginTop: theme.spacing(6),
    padding: theme.spacing(8),
    boxShadow: '0px 4px 24px rgb(0 0 0 / 10%)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: 'auto',
      boxShadow: 'none',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  body: {
    marginTop: theme.spacing(1),
  },
  checkbox: {
    marginTop: theme.spacing(1),
  },
  box: {
    borderRadius: '4px',
    backgroundColor: '#EEEEEE',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const SignUpFlowPrivacy = ({ nextStep }) => {
  const classes = useStyles();
  const search = useLocation().search;
  const planParam = new URLSearchParams(search).get('plan');
  const yearlyParam = new URLSearchParams(search).get('yearly') === 'true';
  const [agreed, setAgreed] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const getMeResult = useGetMeQuery();
  const [acceptPrivacy, acceptPrivacyResult] = useAcceptPrivacyMutation();
  const [createCheckoutSession, createCheckoutSessionResult] = useCreateStripeCheckoutSessionMutation();
  const [error, setError] = useState(null);
  const [stripeLoading, setStripeLoading] = useState(false);
  const stripePromise = loadStripe(STRIPE_API_KEY);
  
  const handleContinueClick = async () => {
    try {
      setStripeLoading(true);
      await acceptPrivacy({ newsletter }).unwrap();
      const plan = parseInt(planParam, 10);
      if (!plan) {
        nextStep();
        return;
      }

      const priceId = getStripePriceId(plan, yearlyParam);
      const email = getMeResult.data.email;
      const result = await createCheckoutSession({ priceId, email }).unwrap();
      if (!result.sessionId) {
        setError('unknown_error');
        return;
      }

      const stripe = await stripePromise;
      const stripeResult = await stripe.redirectToCheckout({
        sessionId: result.sessionId,
      });

      if (stripeResult.error) {
        setError(stripeResult.error.message);
      }
      setStripeLoading(false);
    } catch (err) {
      setError('unknown_error');
      setStripeLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <SecurityIcon />
        </Avatar>
        <Typography component="h1" variant="h5" style={{ fontSize: '1.47rem' }}>
          We take your privacy
          seriously
        </Typography>
        <Typography variant="body1" className={classes.body}>
          We care about our users and have always
          been dedicated to protecting your data and privacy - that&apos;s why we want to be
          extra-clear about what data we collect and how we use it to improve your experience.
        </Typography>
        <Box className={classes.box}>
          <FormControlLabel
            className={classes.checkbox}
            control={<Checkbox color="primary" />}
            checked={agreed}
            disabled={
              stripeLoading
              || acceptPrivacyResult.isLoading
              || createCheckoutSessionResult.isLoading
            }
            onChange={() => setAgreed(!agreed)}
            label={(
              <div>
                <Typography display="inline" variant="body2">I agree to the </Typography>
                <Link href={`${WEBSITE_URL}/privacy-policy`} target="_blank" variant="body2">Terms</Link>
                <Typography display="inline" variant="body2"> and </Typography>
                <Link href={`${WEBSITE_URL}/terms-of-service`} target="_blank" variant="body2">Privacy Policy</Link>
              </div>
            )}
          />
          <FormControlLabel
            control={<Checkbox color="primary" />}
            checked={newsletter}
            disabled={
              stripeLoading
              || acceptPrivacyResult.isLoading
              || createCheckoutSessionResult.isLoading
            }
            onChange={() => setNewsletter(!newsletter)}
            label={(
              <Typography variant="body2">
                I agree to receive periodic product updates, offers,
                and promotions
              </Typography>
            )}
          />
        </Box>

        <Button
          fullWidth
          disabled={
            !agreed
            || stripeLoading
            || acceptPrivacyResult.isLoading
            || createCheckoutSessionResult.isLoading
          }
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleContinueClick}
        >
          {stripeLoading || acceptPrivacyResult.isLoading || createCheckoutSessionResult.isLoading
            ? <CircularProgress size={24} /> : <span>Continue</span>}
        </Button>
        <Snackbar open={error != null} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            <span>
              An error occurred. Please try again or
              <a href="mailto:support@visionar.com"> contact support</a>
              .
            </span>
          </Alert>
        </Snackbar>
      </div>
    </Container>
  );
};

export default SignUpFlowPrivacy;

SignUpFlowPrivacy.propTypes = {
  nextStep: PropTypes.func,
};

SignUpFlowPrivacy.defaultProps = {
  nextStep: null,
};
