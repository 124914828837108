import {connect} from "react-redux";
import ProjectItem from "../components/project-item";
import {selectProject} from "../actions/project";
import { getHasSingleNewProject } from '../selectors/project';

const mapStateToProps = (state, props) => ({
    item: props.item,
    initialClick: getHasSingleNewProject(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    selectProject: (id) => { dispatch(selectProject(id)); },
});

export default connect(mapStateToProps, mapDispatchToProps) (ProjectItem);
