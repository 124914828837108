import React from 'react';

const styles = {
  header: {
    width: '100%',
    display: 'flex',
  },
  headerPart1: {
    width: '33.3%',
    // backgroundColor: '#1D6AAD',
    backgroundColor: 'var(--primaryColor)',
    height: '25px',
  },
  headerPart2: {
    width: '33.3%',
    backgroundColor: 'var(--primaryHover)',
    height: '25px',
  },
  headerPart3: {
    width: '33.4%',
    backgroundColor: 'var(--secondaryColor)',
    height: '25px',
  },
};

const DialogHeader = () => (
  <div style={styles.header}>
    <div style={styles.headerPart1} />
    <div style={styles.headerPart2} />
    <div style={styles.headerPart3} />
  </div>
);

export default DialogHeader;
