import { connect } from 'react-redux';
import Editor from '../components/editor';
import { getItemsOfSelectedTarget, getSelectedTarget } from '../selectors/target';
import { selectTargetItem } from '../actions/content';

const mapStateToProps = (state, props) => ({
  target: getSelectedTarget(state),
  items: getItemsOfSelectedTarget(state),
  previewMode: props.previewMode,
});

const mapDispatchToProps = (dispatch) => ({
  selectItem: () => { dispatch(selectTargetItem(null)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
