import {createAction} from "redux-actions";
import visionarService from '../services/visionar';
import {setError} from "./app";

export const SELECT_PROJECT = 'SELECT_PROJECT';
export const LOAD_PROJECTS_ASYNC_SUCCESS = 'LOAD_PROJECTS_ASYNC_SUCCESS';
export const LOAD_PROJECT_ASYNC_SUCCESS = 'LOAD_PROJECT_ASYNC_SUCCESS';
export const UPDATE_PROJECT_ASYNC_REQUEST = 'UPDATE_PROJECT_ASYNC_REQUEST';
export const UPDATE_PROJECT_ASYNC_SUCCESS = 'UPDATE_PROJECT_ASYNC_SUCCESS';
export const VERIFY_VUFORIA_KEYS_ASYNC_REQUEST = 'VERIFY_VUFORIA_KEYS_ASYNC_REQUEST';
export const VERIFY_VUFORIA_KEYS_ASYNC_SUCCESS = 'VERIFY_VUFORIA_KEYS_ASYNC_SUCCESS';
export const VERIFY_VUFORIA_KEYS_ASYNC_FAIL = 'VERIFY_VUFORIA_KEYS_ASYNC_FAIL';
export const VERIFY_SECRET_KEY_ASYNC_REQUEST = 'VERIFY_SECRET_KEY_ASYNC_REQUEST';
export const VERIFY_SECRET_KEY_ASYNC_SUCCESS = 'VERIFY_SECRET_KEY_ASYNC_SUCCESS';
export const VERIFY_SECRET_KEY_ASYNC_FAIL = 'VERIFY_SECRET_KEY_ASYNC_FAIL';
export const RESET_SECRET_KEY_VERIFIED = 'RESET_SECRET_KEY_VERIFIED';
export const SET_HAS_SINGLE_NEW_PROJECT = 'SET_HAS_SINGLE_NEW_PROJECT';

export const selectProject = createAction(SELECT_PROJECT);
export const loadProjectsAsyncSucces = createAction(LOAD_PROJECTS_ASYNC_SUCCESS);
export const loadProjectAsyncSucces = createAction(LOAD_PROJECT_ASYNC_SUCCESS);
export const updateProjectAsyncRequest = createAction(UPDATE_PROJECT_ASYNC_REQUEST);
export const updateProjectAsyncSuccess = createAction(UPDATE_PROJECT_ASYNC_SUCCESS);
export const verifyVuforiaKeyAsyncRequest = createAction(VERIFY_VUFORIA_KEYS_ASYNC_REQUEST);
export const verifyVuforiaKeyAsyncSuccess = createAction(VERIFY_VUFORIA_KEYS_ASYNC_SUCCESS);
export const verifyVuforiaKeyAsyncFail = createAction(VERIFY_VUFORIA_KEYS_ASYNC_FAIL);
export const verifySecretKeyAsyncRequest = createAction(VERIFY_SECRET_KEY_ASYNC_REQUEST);
export const verifySecretKeyAsyncSuccess = createAction(VERIFY_SECRET_KEY_ASYNC_SUCCESS);
export const verifySecretKeyAsyncFail = createAction(VERIFY_SECRET_KEY_ASYNC_FAIL);
export const resetSecretKeyVerified = createAction(RESET_SECRET_KEY_VERIFIED);
export const setHasSingleNewProject = createAction(SET_HAS_SINGLE_NEW_PROJECT);

export const loadProjects = (userId: String, select: Boolean) => (
    async (dispatch: Function) => {
        try {
            const projects = await visionarService.getUserProjects(userId);
            dispatch(loadProjectsAsyncSucces(projects));
            if (projects !== undefined && projects.length > 0 && select) {
                dispatch(selectProject(projects[0].id));
            }
        } catch (error) {
        }
    }
);

export const loadProjectsAndSelect = (idToSelect: String) => (
  async (dispatch: Function) => {
      try {
          const projects = await visionarService.getUserProjects();
          dispatch(loadProjectsAsyncSucces(projects));
          const projectToSelect = projects.find(p => p.id === idToSelect);
          if (projectToSelect) {
            dispatch(selectProject(projectToSelect.id));
          }
      } catch (error) {
      }
  }
);

export const updateProject = (project: any) => (
    async (dispatch: Function, getState: Function) => {
        try {
            dispatch(updateProjectAsyncRequest(project));
            visionarService.updateProject(project);
            dispatch(updateProjectAsyncSuccess(project));
        } catch (error){

        }
    }
);

export const getKeyFile = (project, productKey) => (
  async () => {
    try {
      visionarService.getProjectKeyFile(project, productKey);
    } catch (error){
    }
  }
);

export const recreateKeyFile = (project, productKey) => (
  async () => {
    try {
      visionarService.getProjectKeyFile(project, productKey, true);
    } catch (error){
    }
  }
);

export const verifyVuforiaKeys = (project) => (
  async (dispatch) => {
    try {
      dispatch(verifyVuforiaKeyAsyncRequest(true));
      const verified = await visionarService.verifyVuforiaKeys(project);
      if (verified) {
        dispatch(verifyVuforiaKeyAsyncSuccess());
      } else {
        dispatch(verifyVuforiaKeyAsyncFail());
      }
    } catch (error){
      dispatch(verifyVuforiaKeyAsyncFail());
    }
  }
);

export const verifySecretKey = (userId, secret) => (
  async (dispatch) => {
    try {
      dispatch(verifySecretKeyAsyncRequest(true));
      const verified = await visionarService.verifySecretKey(userId, secret);
      if (verified) {
        dispatch(verifySecretKeyAsyncSuccess());
      } else {
        dispatch(verifySecretKeyAsyncFail());
        dispatch(setError('The inserted secret key is invalid.'));
      }
    } catch (error){
      dispatch(verifySecretKeyAsyncFail());
    }
  }
);

export const saveAppKey = (projectId, projectKey) => (
    async () => {
        try {
            visionarService.setAppKey(projectId, projectKey);
        } catch (error){
        }
    }
);

