import {connect} from "react-redux";

import Projects from '../components/projects-list';
import {getIsDemoOnly, getProjects} from "../selectors/project";
import {getCurrentUser} from "../selectors/setting";

const mapStateToProps = state => ({
    projects: getProjects(state),
    isDemoOnly: getIsDemoOnly(state),
    user: getCurrentUser(state)
});

export default connect(mapStateToProps)(Projects);
