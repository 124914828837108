import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const styles = {
  okButton: {
    padding: '10px',
    width: '100px',
    border: '0',
    borderRadius: '0',
    margin: '20px',
  },
};

export class ErrorView extends React.Component {
  clearError() {
    this.props.setError(undefined);
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} backdrop="static">
          <ModalHeader>
            <i className="fa fa-exclamation-circle" style={{ marginRight: '15px', color: 'red' }} />
            Attention
          </ModalHeader>
          <ModalBody>
            {this.props.error}
          </ModalBody>
          <div>
            <button className="btn float-right" onClick={this.clearError.bind(this)} style={styles.okButton}>
              OK
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ErrorView;
