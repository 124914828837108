import React from 'react';
import {
  DropdownItem, DropdownMenu, DropdownToggle, Dropdown,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useGetMeQuery, useCreateStripePortalSessionMutation } from '../services/visionarRTK';
import { WEBSITE_URL } from '../config';
import TutorialWizard from '../containers/tutorial-wizard';
import { Portal } from '@mui/material';

const styles = {
  dropDownMenuBtn: {
    color: 'black',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    fontSize: '20px',
    padding: '0px',
    height: '32px',
    width: '32px',
  },
  dropDownMenuItem: {
    color: 'var(-primaryText)',
  },
  icon: {
    marginRight: '5px',
    width: '20px',
  },
  avatar: {
    borderRadius: '90px',
    maxWidth: '40px',
    margin: '2px',
    height: '32px',
    width: '32px',
  },
};

const UserBar = (props) => {
  const { data, isLoading } = useGetMeQuery();
  const [createPortalSession, createPortalSessionResult] = useCreateStripePortalSessionMutation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('access-token');
    props.resetStore();
    history.push('/login');
  };
  
  const handleMyAccount = async () => {
    const customerId = data.stripeCustomerId;
    const result = await createPortalSession({ customerId }).unwrap();
    window.location.href = result.sessionUrl;
  };

  return (
    <div>
      <Button aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
        {
          data
            ? <Avatar alt={data.displayName} src={data.picture} />
            : <Avatar><PersonIcon /></Avatar>
        }
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        elevation={0}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={isLoading || createPortalSessionResult.isLoading} onClick={handleMyAccount}>My account</MenuItem>
        <MenuItem onClick={handleLogout}>Sign out</MenuItem>
      </Menu>
    </div>
  );
};

class UserBar2 extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      user: null,
    };
  }

  avatar;

  componentDidMount() {
    // this.props.loadUser(sessionStorage.getItem('user'));
    const { data, error, isLoading } = useGetMeQuery();
    this.avatar = undefined;
  }

  toggle() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  performLogout() {
    sessionStorage.removeItem('access-token');
    this.props.resetStore();
  }

  recreatePdf() {
    this.props.recreateKeyFile(this.props.selectedProject, localStorage.getItem('project-key'));
  }

  render() {
    const element = document.getElementById('avatar');
    // if (element && this.props.user && !this.avatar) {
    if (element && !this.avatar) {
      this.avatar = new Avatar(element, {
        useGravatar: true,
        // email: this.props.user.email,
        email: 'thomas.toffalori@gmail.com',
        initials: this.props && this.props.user && this.props.user.displayName ? this.props.user.email.substring(0, 2).toUpperCase() : 'V',
        initial_fg: this.props.invertColor ? 'white' : 'black', // Text Color
        initial_bg: 'transparent', // Background Color
        initial_font_family: "'Open Sans', 'Lato', 'Lato-Regular', 'Helvetica Neue'",
      });
    }

    return (
      <div>
        <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
          <DropdownToggle style={styles.dropDownMenuBtn}>
            {/* <i className="fa fa-angle-down" style={{paddingRight: '5px'}}></i><span>{this.props.user ? this.props.user.displayName: ''}</span> */}
            <img style={styles.avatar} id="avatar" alt="profile_picture" />
          </DropdownToggle>
          <DropdownMenu right>
            {/* <DropdownItem style={styles.dropDownMenuItem} href="https://www.visionar.com/">
                <i className="fa fa-globe" style={styles.icon}></i>To website
            </DropdownItem> */}
            <DropdownItem style={styles.dropDownMenuItem} href={`${WEBSITE_URL}/pricing-plans`}>
              <i className="fa fa-plus" style={styles.icon} />
              Purchase license
            </DropdownItem>
            {/* { */}
            {/*  this.props.user && this.props.user.hasSecretKey && localStorage.getItem('project-key') ? */}
            {/*  <DropdownItem style={styles.dropDownMenuItem} onClick={this.recreatePdf.bind(this)}> */}
            {/*    <i className="fa fa-file-o" style={styles.icon}></i>Private key */}
            {/*  </DropdownItem> : undefined */}
            {/* } */}

            <DropdownItem divider />
            {/* <DropdownItem style={styles.dropDownMenuItem} href="http://docs.visionar.com/"> */}
            {/*  <i className="fa fa-align-left" style={styles.icon}></i>Documentation */}
            {/* </DropdownItem> */}
            <DropdownItem style={styles.dropDownMenuItem} onClick={this.props.toggleShowTutorial}>
              <i className="fa fa-lightbulb-o" style={styles.icon} />
              Tips
            </DropdownItem>
            <DropdownItem style={styles.dropDownMenuItem} target="_blank" href={`${WEBSITE_URL}/support`}>
              <i className="fa fa-envelope" style={styles.icon} />
              Support
            </DropdownItem>
            <DropdownItem divider style={styles.dropDownDivider} />
            <DropdownItem
              style={styles.dropDownMenuItem}
              target="_blank"
              href={`${WEBSITE_URL}/my-account/edit-account/`}
            >
              ;
              <i className="fa fa-lock" style={styles.icon} />
              Change password
            </DropdownItem>
            <DropdownItem
              style={styles.dropDownMenuItem}
              href={`${WEBSITE_URL}/wp-login.php?action=logout`}
              onClick={this.performLogout.bind(this)}
            >
              <i className="fa fa-sign-out" style={styles.icon} />
              Sign out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <TutorialWizard />
      </div>
    );
  }
}

// export default Radium(UserBar);
export default UserBar;
