import React, {
  useRef, useState, SyntheticEvent, useLayoutEffect,
} from 'react';
import { grey } from '@mui/material/colors';
import Target from '../models/target';
import TargetItem from '../models/target-item';
// @ts-ignore
import TargetItemView from '../containers/target-item';
import CanvasToolbar from '../components/canvas-toolbar';

type CanvasProps = {
  target: Target,
  items: [TargetItem],
  previewMode: boolean,
  selectItem: () => void,
};

function Canvas({
  target, items, previewMode, selectItem,
}: CanvasProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const targetImageRef = useRef<HTMLDivElement>(null);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [refAcquired, setRefAcquired] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [isZoomedOut, setZoomedOut] = useState(false);
  const [gridIsOn, setGridIsOn] = useState(true);

  useLayoutEffect(() => {
    if (isZoomedOut) {
      setZoomedOut(false);
    }

    scrollToCenter();
  }, [previewMode]);

  useLayoutEffect(() => {
    if (imageSize.width === 0 || imageSize.height === 0) return;
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const scaleFactorWidth = containerRect.width / imageSize.width;
      const scaleFactorHeight = containerRect.height / imageSize.height;
      const scaleFactorValue = Math.min(scaleFactorHeight, scaleFactorWidth);
      setScaleFactor(Math.min(scaleFactorValue * 0.95, 1));
      setRefAcquired(!refAcquired);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [imageSize.height, imageSize.width]);

  // The refAcquired step is required, otherwise the ref still holds the old value and scrolls to the wrong position
  // Found the solution here: https://stackoverflow.com/questions/65941536/useref-value-is-undefined-on-initial-render
  useLayoutEffect(() => {
    if (imageSize.width === 0 || imageSize.height === 0) return;
    if (targetImageRef.current) {
      targetImageRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [refAcquired]);

  const onTargetImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    setImageSize({
      width: e.currentTarget.naturalWidth,
      height: e.currentTarget.naturalHeight,
    });
  };

  const scrollToCenter = () => {
    if (targetImageRef.current) {
      targetImageRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }

  const onZoomButtonClicked = () => {
    setZoomedOut(!isZoomedOut)
    scrollToCenter();
  }

  return (
    <div
      ref={containerRef}
      style={{
        flexGrow: 1,
        overflow: isZoomedOut ? 'hidden' : 'scroll',
        marginTop: '2px',
        padding: '16px',
        overflowAnchor: 'none',
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        id="canvas"
        role="main"
        onClick={(e) => {
          const clickedElement = e.target as HTMLElement;
          if (clickedElement.id === 'canvas' || clickedElement.id === 'target') {
            selectItem();
          }
        }}
        style={{
          margin: 'auto',
          position: 'relative',
          width: `calc( 
              ${Math.max(imageSize.width, imageSize.height) * scaleFactor}px 
              + ${imageSize.width * scaleFactor}px 
            )`,
          height: `calc( 
              ${Math.max(imageSize.width, imageSize.height) * scaleFactor}px 
              + ${imageSize.height * scaleFactor}px 
            )`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: `2px solid ${grey[400]}`,
          borderRight: `3px solid ${grey[400]}`,
          borderBottom: `3px solid ${grey[400]}`,
          backgroundImage: gridIsOn ? isZoomedOut ? 'repeating-linear-gradient(#bdbdbd 0 2px, transparent 2px 100%), repeating-linear-gradient(\n 90deg, #bdbdbd 0 2px, transparent 2px 100%)' : 'repeating-linear-gradient(#bdbdbd 0 1px, transparent 1px 100%), repeating-linear-gradient(\n 90deg, #bdbdbd 0 1px, transparent 1px 100%)' : '',
          backgroundSize: 'min(24px, 24px) min(24px, 24px)',
          // transitionDuration: '0.1s',
          // transitionProperty: 'scale',
          scale: isZoomedOut ? '0.5' : '1.0',
        }}
      >
        <div
          id="targetImage"
          ref={targetImageRef}
          style={{
            width: `calc(${imageSize.width * scaleFactor}px)`,
            height: `calc(${imageSize.height * scaleFactor}px)`,
          }}
        >
          <img
            id="target"
            src={target.filePath}
            onLoad={onTargetImageLoad}
            style={{
              width: '100%',
              height: '100%',
              display: 'block',
              border: `1px solid ${grey[400]}`,
              opacity: previewMode ? '1' : '0.4',
              userSelect: 'none',
              MozUserSelect: 'none',
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
            }}
            alt="Placeholder"
          />
        </div>
        {
          items && !previewMode ? items.map((item) => (
            <TargetItemView
              key={item.id}
              item={item}
              scaleFactor={scaleFactor}
              originalImageSize={imageSize}
              targetId={target.id}
              canvasScale={isZoomedOut ? 0.5 : 1.0}
              canvasSize={{
                width: Math.max(imageSize.width, imageSize.height) + imageSize.width,
                height: Math.max(imageSize.width, imageSize.height) + imageSize.height,
              }}
            />
          )) : null
        }

      </div>
      <CanvasToolbar isZoomedOut={isZoomedOut} isGridOn={gridIsOn} onGridButtonClicked={() => setGridIsOn(!gridIsOn)} onZoomButtonClicked={onZoomButtonClicked} />
    </div>
  );
}

export default Canvas;
