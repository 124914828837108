export const isValidImage = (file) => new Promise((resolve, reject) => {
  file.arrayBuffer().then((buffer) => {
    const view = new DataView(buffer);

    // is a PNG?
    if (view.getUint32(0) === 0x89504E47 && view.getUint32(4) === 0x0D0A1A0A) {
      // We know format field exists in the IHDR chunk. The chunk exists at
      // offset 8 +8 bytes (size, name) +8 (depth) & +9 (type)
      const depth = view.getUint8(8 + 8 + 8);
      const type = view.getUint8(8 + 8 + 9);
      if (type === 4 || type === 6) {
        reject('Invalid target: Targets must be jpeg or 8 or 24 bit png without transparency.');
      } else {
        resolve(file);
      }
    } else {
      resolve(file);
    }
  });
});
