import React from 'react';

function Playground() {
  return (
    <div>
      <h2 style={{ padding: '16px' }}>Playground</h2>
      <hr />
      <div style={{ padding: '64px' }} />
    </div>
  );
}

export default Playground;
