// @flow

import { connect } from 'react-redux';

import { uploadTargets } from '../actions/target';
import DragAndDrop from '../components/drag-drop';
import {setError} from "../actions/app";
import {getSelectedProject} from "../selectors/project";

const mapStateToProps = state => ({
  isUploading: false,
  selectedProject: getSelectedProject(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  uploadTargets: (projectId, files) => { dispatch(uploadTargets(projectId, files)); },
  setError: (error) => { dispatch(setError(error)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(DragAndDrop);
