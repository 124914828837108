// @flow

import { connect } from 'react-redux';
import PreviewInstructions from '../components/preview-instructions';

const mapStateToProps = (state, props) => ({
  previewMode: props.isPreview,
  selectedProject: props.project,
});

export default connect(mapStateToProps)(PreviewInstructions);
