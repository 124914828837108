import Immutable from 'immutable';
import {
  CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS,
  CREATE_STRIPE_CHECKOUT_SESSION_FAILURE,
} from '../actions/payment';

const initialState = Immutable.fromJS({
  stripeCheckoutSessionId: null,
});

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS:
      return state.set('stripeCheckoutSessionId', Immutable.fromJS(action.payload.sessionId));
    case CREATE_STRIPE_CHECKOUT_SESSION_FAILURE:
      return state.set('error', 'Failed to create stripe checkout sessionId');
    default:
      return state;
  }
};

export default paymentReducer;
