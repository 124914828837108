import React from 'react';
import Radium from 'radium';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import studioLogo from '../images/visionar_studio_logo.svg';
import UserBar from '../containers/user-bar';
import { WEBSITE_URL } from '../config';

const styles = {
  navbar: {
    backgroundColor: 'transparent',
  },
  studioLogo: {
    width: '218.3px',
  },
};

const DefaultNavigationBar = () => (
  <Navbar style={styles.navbar} dark fixed="top" expand="md">
    <Link to="/" style={styles.linkButton}>
      <img style={styles.studioLogo} src={studioLogo} alt="background" />
    </Link>

    <Nav className="ml-auto align-items-center" navbar>
      <NavItem>
        <NavLink href={`${WEBSITE_URL}/blog/`} target="_blank">What&apos;s new?</NavLink>
      </NavItem>
      <NavItem>
        <UserBar />
      </NavItem>
    </Nav>
  </Navbar>
);

export default Radium(DefaultNavigationBar);
