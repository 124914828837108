import { connect } from 'react-redux';

import TargetItem from '../components/target-item';
import {
  updateItem, removeItem, selectTargetItem, uploadContent
} from '../actions/content';
import { getSelectedItem } from '../selectors/target';

const mapStateToProps = (state, props) => ({
  item: props.item,
  targetId: props.targetId,
  scaleFactor: props.scaleFactor,
  originalImageSize: props.originalImageSize,
  isSelected: getSelectedItem(state) === props.item.id,
  canvasSize: props.canvasSize,
});

const mapDispatchToProps = (dispatch) => ({
  updateItem: (item) => { dispatch(updateItem(item)); },
  removeItem: (item) => { dispatch(removeItem(item)); },
  selectItem: (id) => { dispatch(selectTargetItem(id)); },
  uploadContent: (payload) => { dispatch(uploadContent(payload.targetId, payload.itemId, payload.file)); },
});

// flow-disable-next-line
export default connect(mapStateToProps, mapDispatchToProps)(TargetItem);
