import React from 'react';
import Radium from 'radium';
import { WEBSITE_URL } from '../config';

const styles = {
  footer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '50px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',

  },
  footerLink: {
    fontWeight: '400',
    color: 'var(--secondaryText)',
    textDecoration: 'none',
    ':hover': {
      color: 'var(--primaryColor)',
    },
  },
};

const Footer = () => (
  <div>
    <footer style={styles.footer}>
      <a className="btn-text" style={styles.footerLink} target="_blank" key="privacy" rel="noopener noreferrer" href={`${WEBSITE_URL}/privacy-policy/`}>Privacy Policy</a>
      <span style={{ color: 'var(--secondaryText)', textDecoration: 'none', padding: '0 10px 0 10px' }}>·</span>
      <a className="btn-text" style={styles.footerLink} target="_blank" key="terms" rel="noopener noreferrer" href={`${WEBSITE_URL}/terms-of-service/`}>Terms</a>
      <span style={{ color: 'var(--secondaryText)', textDecoration: 'none', padding: '0 10px 0 10px' }}>·</span>
      <a className="btn-text" style={styles.footerLink} target="_blank" key="imprint" rel="noopener noreferrer" href={`${WEBSITE_URL}/imprint`}>Imprint</a>
    </footer>
  </div>
);

export default Radium(Footer);
