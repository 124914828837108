// @flow

import { connect } from 'react-redux';
import ProgressView from '../components/progress-view';

const mapStateToProps = (state) => ({
  isOpen: state.target.get('isUploading'),
  value: state.target.get('uploadPercentage'),
});

export default connect(mapStateToProps)(ProgressView);
