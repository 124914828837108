// @flow

export function mapTarget(target: Object) {
  return {
    id: target._id,
    name: target.name,
    projectId: target.projectId,
    filePath: target.filePath,
    items: target.items.map((item) => ({
      id: item.id,
      x: item.x,
      y: item.y,
      z: item.z || 1,
      name: item.name,
      width: item.width,
      height: item.height,
      targetWidth: item.targetWidth,
      targetHeight: item.targetHeight,
      isFullscreen: item.isFullscreen,
      type: item.type,
      url: item.url,
      linkUrl: item.linkUrl,
      greenScreenColor: item.greenScreenColor,
    })),
    vuforiaStatus: target.vuforiaStatus ? target.vuforiaStatus : 'failed',
    vuforiaRating: target.vuforiaRating ? target.vuforiaRating : -1,
    physicalWidth: target.physicalWidth,
    quality: target.quality
  };
}

export function mapProject(project: Object) {
  return {
    id: project._id,
    name: project.name,
    isNewProject: project.isNewProject,
    isDeveloperProject: project.isDeveloperProject,
    subscriptionStatus: project.subscriptionStatus,
    subscriptionId: project.subscriptionId,
    maxTargets: project.maxTargets,
    targetsCount: project.targetsCount,
    licenseInfo: mapProjectLicense(project.license),
    user: project.user,
    accessCode: project.accessCode,
    appKey: project.appKey,
  };
}

export function mapTargetResponse(responseData: Object) {
  return responseData.map((t) => mapTarget(t));
}

export function mapUser(user) {
  return {
    id: user.id,
    displayName: user.displayName,
    hasSecretKey: user.hasSecretKey,
    email: user.email,
    neverShowTips: user.neverShowTips,
    secretKey: user.secretKey,
  };
}

function mapProjectLicense(license) {
  switch (license) {
    case 'free':
      return 'Visionar Free';
    case 'personal':
      return 'Visionar Personal';
    case 'basic':
      return 'Visionar Basic';
    case 'standard':
      return 'Visionar Standard';
    case 'pro':
      return 'Visionar Pro';
    default:
      return 'Visionar Free';
  }
}
