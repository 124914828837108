// @flow

import React from 'react';
import Radium from 'radium';
import Dropzone from 'react-dropzone';
import png from '../images/file_png.png';
import jpg from '../images/file_jpg.png';
import {isValidImage} from "../image-tools";
import Button from './button';

const styles = {
  dropzone: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    minHeight: '349px',
    borderWidth: '2px',
    borderStyle: 'dashed',
    borderRadius: '8px',
    borderColor: 'var(--secondaryText)',
    padding: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '700px'
  },
  dropzoneActive: {
    backgroundColor: '#d2edc1',
    borderStyle: 'solid',
    borderColor: 'limegreen',
  },
  dropzoneReject: {
    backgroundColor: '#edabb2',
    borderStyle: 'solid',
    borderColor: 'red',
  },
  uploadButton: {
    width: '33%',
    display: 'block',
    margin: 'auto',
    padding: '10px',
    fontSize: '0.8em',
    color: 'white',
    backgroundColor: '#115CA8',
    border: '0',
    borderRadius: '0',
    ':hover': {
      backgroundColor: '#006ae2',
    },
    ':focus': {
      boxShadow: '0',
      outline: '0',
    },
  },
  fileTypeButton: {
    margin: '10px',
    width: '100px',
    height: '100px',
    userDrag: 'none',
    userSelect: 'none',
    WebkitUserDrag: 'none'
  },
};

let dropzoneRef;

export class DragAndDrop extends React.Component  {
  async onDropAccepted(files) {
    const results = [];
    files.forEach(f => {
      results.push(isValidImage(f));
    });

    await Promise.all(results).then(_ => {
      this.props.uploadTargets(this.props.selectedProject.id, files);
    }, error => this.props.setError(error));
  }

  onDropRejected = () => {
    this.setState({error: 'Max upload size is 10MB per target'});
    this.props.setError('Max upload size is 10MB per target');
  }

  render() {
    return (<div style={{ padding: '32px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Dropzone
            style={styles.dropzone}
            activeStyle={styles.dropzoneActive}
            rejectStyle={styles.dropzoneReject}
            accept="image/jpeg, image/png"
            onDropAccepted={this.onDropAccepted.bind(this)}
            onDropRejected={this.onDropRejected.bind(this)}
            multiple={true}
            maxSize={2097152 * 5}
            disableClick
            ref={(node) => { dropzoneRef = node; }}
        >
            { !this.props.isUploading ?
                <div>
          <span style={{ textAlign: 'center' }}>
            <h2 style={{ color: 'var(--primaryColor)' }}>Drag & Drop</h2>
            <h3 style={{ color: 'var(--primaryText)', fontWeight: '200' }}>files here</h3>
          </span>
                    <span style={{ paddingTop: '20px', textAlign: 'center', display: 'block' }}>
            <img alt="png" src={png} style={styles.fileTypeButton} />
            <img alt="jpg" src={jpg} style={styles.fileTypeButton} />
          </span>
                </div>
                :
                <div>
                    <h6 style={{ textAlign: 'center' }}>Uploading...</h6>
                    <div className="progress" style={{ width: '100%', margin: 'auto' }}>
                        <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            }
        </Dropzone>
        { !this.props.isUploading ?
            <div>
                <h5 style={{ textAlign: 'center', fontWeight: '300', color: 'var(--secondaryText)', padding: '10px' }} >or</h5>
                <Button width="230px" label="Upload from PC" shadow handleClick={() => { dropzoneRef.open(); }} />
            </div>
            : <div />
        }
    </div>);
  }
}


export default Radium(DragAndDrop);
