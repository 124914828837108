import { connect } from 'react-redux';
import { getIsSecretKeyVerified, getIsSecretKeyVerifyRunning } from '../selectors/project';
import { resetSecretKeyVerified, verifySecretKey } from '../actions/project';
import { ProjectKey } from '../components/project-key';

const mapStateToProps = (state, props) => ({
  secretKeyVerifyRunning: getIsSecretKeyVerifyRunning(state),
  secretKeyVerified: getIsSecretKeyVerified(state),
});

const mapDispatchToProps = (dispatch) => ({
  verifySecretKey: (userId, secret) => { dispatch(verifySecretKey(userId, secret)); },
  resetSecretKeyVerified: () => { dispatch(resetSecretKeyVerified()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectKey);
