import { connect } from 'react-redux';
import ErrorView from "../components/error-view";
import {setError} from "../actions/app";

const mapStateToProps = state => ({
    isOpen: !!state.setting.get('error'),
    error: state.setting.get('error'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setError: (error) => { dispatch(setError(error)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorView);