import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Link from '@mui/material/Link';
import ProjectList from '../../containers/project-list';
import DefaultNavigationBar from '../default-navigation-bar';
import desk1 from '../../images/visionar-desk-01.png';
import desk2 from '../../images/visionar-desk-02.png';
import ErrorView from '../../containers/error-view';
import Footer from '../footer';
import { WEBSITE_URL } from '../../config';
import { useCreateStripePortalSessionMutation, useGetMeQuery } from '../../services/visionarRTK';

const styles = {
  hero: {
    height: '506px',
    backgroundImage: 'linear-gradient(to bottom right, var(--secondaryColor), var(--primaryColor))',
    position: 'absolute',
    width: '100%',
    zIndex: '-1',
    top: '0',
  },
  heroBackground: {
    height: '506px',
    backgroundImage: `url(${desk1}), url(${desk2})`,
    backgroundPosition: 'left -2px bottom -2px,right -12px bottom -2px',
    backgroundRepeat: 'no-repeat',
  },
  helperLinks: {
    color: 'var(--text)',
    opacity: '0.75',
    ':hover': {
      textDecoration: 'none',
      opacity: '1',
    },
  },
  container: {
    width: '975px',
    paddingTop: '134px',
  },
  content: {
    paddingBottom: '130px',
  },
};

const useStyles = makeStyles((theme) => ({
  helperLinks: {
    color: theme.palette.common.white,
    opacity: '0.75',
    '&:hover': {
      textDecoration: 'none',
      opacity: '1',
      cursor: 'pointer',
      color: theme.palette.common.white,
    },
  },
}));

const ProjectPage = () => {
  const classes = useStyles();
  const { data, isLoading } = useGetMeQuery();
  const [createPortalSession, createPortalSessionResult] = useCreateStripePortalSessionMutation();

  const handleMyAccount = async () => {
    const customerId = data.stripeCustomerId;
    const result = await createPortalSession({ customerId }).unwrap();
    window.location.href = result.sessionUrl;
  };

  return (
    <div style={{ minHeight: '100vh', position: 'relative' }}>
      <DefaultNavigationBar />
      <div style={styles.content}>
        <div style={styles.hero}>
          <div style={styles.heroBackground} />
        </div>
        <Container style={styles.container}>
          <Row>
            <Col><h4 style={{ color: 'var(--text)' }}>Welcome to visionar studio</h4></Col>
          </Row>
          <Row>
            <Col><p style={{ color: 'var(--text)' }}>The easiest way to create your own augmented reality experience.</p></Col>
          </Row>
          <Row style={{ marginTop: '24px' }}>
            <Col xs="3">
              <a className={`btn-text ${classes.helperLinks}`} target="_blank" key="helper01" rel="noopener noreferrer" href={`${WEBSITE_URL}`}>
                <i className="fa fa-lightbulb-o" aria-hidden="true"> </i>
                {' '}
                Learn more
              </a>
            </Col>
            <Col xs={{ size: '3', offset: 0 }}>
              <a className={`btn-text ${classes.helperLinks}`} target="_blank" key="helper02" rel="noopener noreferrer" href={`${WEBSITE_URL}/faq`}>
                <i className="fa fa-comment-o" aria-hidden="true"> </i>
                {' '}
                Support
              </a>
            </Col>
            <Col xs={{ size: '3', offset: 0 }}>
              <Link className={`btn-text ${classes.helperLinks}`} underline="none" color="inherit" key="helper03" rel="noopener noreferrer" component="button" onClick={async () => handleMyAccount()} disabled={isLoading || createPortalSessionResult.isLoading}>
                <i className="fa fa-home" aria-hidden="true"> </i>
                {' '}
                My Account
              </Link>
              {/*<a style={styles.helperLinks} className="btn-text" target="_blank" key="helper03" rel="noopener noreferrer" href={`${WEBSITE_URL}/my-account`}>*/}
              {/*  <i className="fa fa-home" aria-hidden="true"> </i>*/}
              {/*  {' '}*/}
              {/*  My Account*/}
              {/*</a>*/}
            </Col>
          </Row>
        </Container>
        <ProjectList />
      </div>
      <ErrorView />
      <Footer />
    </div>
  );
};
export default ProjectPage;
