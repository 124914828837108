export function getTargets(state) {
  if (!state.target) {
    return undefined;
  }

  return state.target.toJS().targets;
}

export function getIsSelectedTarget(state, targetId) {
  if (!state.target) {
    return false;
  }

  return state.target.toJS().selectedTarget === targetId;
}

export function getSelectedTarget(state) {
  if (!state.target) {
    return undefined;
  }

  const stateAsJson = state.target.toJS();
  if (!stateAsJson.selectedTarget) {
    return undefined;
  }

  return stateAsJson.targets.find(((target) => target.id === stateAsJson.selectedTarget));
}

export function getSelectedItem(state) {
  if (!state.target) {
    return undefined;
  }

  const stateAsJson = state.target.toJS();
  if (!stateAsJson.selectedItem) {
    return undefined;
  }

  return stateAsJson.selectedItem;
}

export function getItemsOfSelectedTarget(state) {
  const selectedTarget = getSelectedTarget(state);
  if (!selectedTarget) {
    return undefined;
  }

  return selectedTarget.items;
}

export function countItemOfSelectedTarget(state) {
  const itemsOfTarget = getItemsOfSelectedTarget(state);
  if (itemsOfTarget) {
    return itemsOfTarget.length;
  }

  return 0;
}

export function getIconNameFromItemType(type) {
  switch (type.toUpperCase()) {
    case 'IMAGE':
      return 'fa-picture-o';
    case 'VIDEO':
      return 'fa-play-circle';
    case 'AUDIO':
      return 'fa-music';
    case 'LINK':
      return 'fa-globe';
    default:
      return null;
  }
}
