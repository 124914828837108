// @flow

import Immutable from 'immutable';
import type { fromJS as Immut } from 'immutable';
import { createAction } from 'redux-actions';
import type { Target, TargetItem } from '../type/target';
import visionarService from '../services/visionar';
import { updateTarget, updateUploadPercentage } from './target';
import { setError } from './app';

export const UPLOAD_CONTENT_ASYNC_REQUEST = 'UPLOAD_CONTENT_ASYNC_REQUEST';
export const UPLOAD_CONTENT_ASYNC_SUCCESS = 'UPLOAD_CONTENT_ASYNC_SUCCESS';
export const UPLOAD_CONTENT_ASYNC_FAILURE = 'UPLOAD_CONTENT_ASYNC_FAILURE';
export const SELECT_TARGET_ITEM = 'SELECT_TARGET_ITEM';

export const uploadContentAsyncRequest = createAction(UPLOAD_CONTENT_ASYNC_REQUEST);
export const uploadContentAsyncSuccess = createAction(UPLOAD_CONTENT_ASYNC_SUCCESS);
export const uploadContentAsyncFailure = createAction(UPLOAD_CONTENT_ASYNC_FAILURE);
export const selectTargetItem = createAction(SELECT_TARGET_ITEM);

export const ObjectId = (m = Math, d = Date, h = 16, s = (f) => m.floor(f).toString(h)) => s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

function createTargetItem(typeValue: string) {
  return {
    id: ObjectId(),
    x: 0,
    y: 0,
    z: 1,
    width: 480,
    height: 360,
    isFullscreen: true,
    type: typeValue,
    url: '',
  };
}

function getSelectedTarget(state: Immut) {
  return state.get('targets').find((target) => target.get('id') === state.get('selectedTarget'));
}

function indexOfItem(target: Target, item: TargetItem) {
  return target.items.findIndex((i) => i.id === item.id);
}

export const addItem = (itemType: string) => (
  async (dispatch: Function, getState: Function) => {
    const target = getSelectedTarget(getState().target);
    if (!target) {
      return;
    }

    const newTarget = target.update('items', (arr) => arr.push(Immutable.fromJS(createTargetItem(itemType))));
    dispatch(updateTarget(newTarget));
  }
);

export const updateItem = (item: TargetItem) => (
  async (dispatch: Function, getState: Function) => {
    const target = getSelectedTarget(getState().target);
    if (!target) {
      return;
    }

    const index = indexOfItem(target.toJS(), item);
    if (index < 0) {
      return;
    }

    let newTarget = target.setIn(['items', index, 'x'], item.x);
    newTarget = newTarget.setIn(['items', index, 'y'], item.y);
    newTarget = newTarget.setIn(['items', index, 'z'], item.z);
    newTarget = newTarget.setIn(['items', index, 'height'], item.height);
    newTarget = newTarget.setIn(['items', index, 'width'], item.width);
    newTarget = newTarget.setIn(['items', index, 'isFullscreen'], item.isFullscreen);
    newTarget = newTarget.setIn(['items', index, 'linkUrl'], item.linkUrl);
    newTarget = newTarget.setIn(['items', index, 'greenScreenColor'], item.greenScreenColor);

    if (!target.targetHeight && !target.targetWidth && item.targetHeight && item.targetWidth) {
      newTarget = newTarget.setIn(['items', index, 'targetHeight'], item.targetHeight);
      newTarget = newTarget.setIn(['items', index, 'targetWidth'], item.targetWidth);
    }

    dispatch(updateTarget(newTarget));
  }
);

export const removeItem = (item: TargetItem) => (
  async (dispatch: Function, getState: Function) => {
    const target = getSelectedTarget(getState().target);
    if (!target) {
      return;
    }

    const index = indexOfItem(target.toJS(), item);
    if (index < 0) {
      return;
    }

    const newTarget = target.removeIn(['items', index]);
    dispatch(updateTarget(newTarget));
  }
);

export const uploadContent = (targetId, itemId, files) => (
  async (dispatch) => {
    try {
      dispatch(uploadContentAsyncRequest());
      const updatedTarget = await visionarService.uploadContent(targetId, itemId, files[0], (value) => {
        dispatch(updateUploadPercentage(value));
      });
      dispatch(uploadContentAsyncSuccess(updatedTarget));
      dispatch(updateUploadPercentage(0));
    } catch (error) {
      dispatch(uploadContentAsyncFailure());
      dispatch(updateUploadPercentage(0));
      dispatch(setError('Upload failed. Please check your network connection'));
    }
  }
);
