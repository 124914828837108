export enum TargetType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  GreenScreen = 'GREEN_SCREEN',
  Audio = 'AUDIO',
  Link = 'LINK',
  YouTube = 'YOUTUBE',
  Vimeo = 'VIMEO',
  Text = 'TEXT',
  ImageGallery = 'IMAGE_GALLERY',
}

export default interface TargetItem {
  id: string,
  type: TargetType
  isFullscreen: boolean,
  name: string,
  url: string,
  linkUrl: string,
  greenScreenColor: string,
  height: number,
  width: number,
  targetHeight: number,
  targetWidth: number,
  x: number,
  y: number,
  z: number,
  lastModified: Date,
  s3Key: string,
}
