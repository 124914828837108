import { default as Immutable } from 'immutable';
import {
  LOAD_PROJECTS_ASYNC_SUCCESS, RESET_SECRET_KEY_VERIFIED, SELECT_PROJECT, UPDATE_PROJECT_ASYNC_REQUEST,
  UPDATE_PROJECT_ASYNC_SUCCESS, VERIFY_SECRET_KEY_ASYNC_FAIL, VERIFY_SECRET_KEY_ASYNC_REQUEST,
  VERIFY_SECRET_KEY_ASYNC_SUCCESS,
  SET_HAS_SINGLE_NEW_PROJECT,
} from '../actions/project';

const initialState = Immutable.fromJS({
  projects: [],
  selectedProject: undefined,
  hasSingleNewProject: false,
  secretKeyVerifyRunning: false,
  secretKeyVerified: false,
});

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROJECTS_ASYNC_SUCCESS: {
      const projects = action.payload.filter((p) => p.subscriptionStatus !== 'TRASH');
      return state.set('projects', Immutable.fromJS(projects))
        .set('hasSingleNewProject', projects.length === 1 && projects[0].isNewProject);
    }
    case SELECT_PROJECT: {
      return state.set('selectedProject', action.payload);
    }
    case UPDATE_PROJECT_ASYNC_REQUEST: {
      return state.set('isUploading', true);
    }
    case UPDATE_PROJECT_ASYNC_SUCCESS: {
      const indexOfProject = state.get('projects').findIndex((project) => project.get('id') === action.payload.id);

      return state.setIn(['projects', indexOfProject], Immutable.fromJS(action.payload))
        .set('isUploading', false)
        .set('activeTab', 1);
    }
    case SET_HAS_SINGLE_NEW_PROJECT: {
      return state.set('hasSingleNewProject', action.payload);
    }
    case VERIFY_SECRET_KEY_ASYNC_REQUEST: {
      return state.set('secretKeyVerifyRunning', true);
    }
    case VERIFY_SECRET_KEY_ASYNC_FAIL: {
      return state.set('secretKeyVerifyRunning', false).set('secretKeyVerified', false);
    }
    case VERIFY_SECRET_KEY_ASYNC_SUCCESS: {
      return state.set('secretKeyVerifyRunning', false).set('secretKeyVerified', true);
    }
    case RESET_SECRET_KEY_VERIFIED: {
      return state.set('secretKeyVerifyRunning', false).set('secretKeyVerified', false);
    }
    default:
      return state;
  }
};

export default projectReducer;
