// @flow

import React from 'react';
import type { Target, TargetItem } from '../type/target';
import DragAndDrop from '../containers/drag-drop';
import Canvas from './canvas';

type Props = {
    target: ?Target,
    items: ?Array<TargetItem>,
    previewMode: Boolean,
};

const Editor = ({ target, items, previewMode, selectItem }: Props) => {
    if (target) {
        return <Canvas target={target} items={items} previewMode={previewMode} selectItem={selectItem} zoomFactor={1} />;
    }

    return null;
    // return <DragAndDrop projectId={projectId}/>;
};

export default Editor;
