import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useCreateStripePortalSessionMutation, useGetMeQuery } from '../../services/visionarRTK';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    marginTop: theme.spacing(6),
    padding: theme.spacing(8),
    boxShadow: '0px 4px 24px rgb(0 0 0 / 10%)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: 'auto',
      boxShadow: 'none',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    margin: theme.spacing(4, 0, 2),
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  footer: {
    marginTop: theme.spacing(8),
  },
  body: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  icon: {
    minWidth: '30px',
    color: theme.palette.primary.main,
  },
  separator: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.50)',
    '&::before': {
      content: '""',
      flex: '1',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    },
    '&::after': {
      content: '""',
      flex: '1',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    },
    '&:not(:empty)::before': {
      marginRight: '.25em',
    },
    '&:not(:empty)::after': {
      marginLeft: '.25em',
    },
  },
}));

const SignUpFlowWelcome = ({ nextStep }) => {
  const classes = useStyles();
  const { data, isLoading } = useGetMeQuery();
  const [createPortalSession] = useCreateStripePortalSessionMutation();

  const handleMyAccount = async () => {
    const customerId = data.stripeCustomerId;
    const result = await createPortalSession({ customerId }).unwrap();
    window.location.href = result.sessionUrl;
  };
  
  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <EmojiPeopleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">Welcome back!</Typography>
        <Typography variant="body1" className={classes.body}>Looks like your subscription has been expired. Would you like to reactivate this account?</Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={nextStep}
        >
          Continue
        </Button>
        {
          !isLoading && data.stripeCustomerId ? (
            <div>
              <div className={classes.separator}>or</div>
              <Button color="primary" onClick={handleMyAccount}>Go to my account</Button>
            </div>
          ) : null
        }
      </div>
    </Container>
  );
};

export default SignUpFlowWelcome;

SignUpFlowWelcome.propTypes = {
  nextStep: PropTypes.func,
};

SignUpFlowWelcome.defaultProps = {
  nextStep: null,
};
