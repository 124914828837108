import { createAction } from 'redux-actions';
import visionarService from '../services/visionar';

export const TOGGLE_PREVIEW_MODE = 'TOGGLE_PREVIEW_MODE';
export const SELECT_TAB = 'SELECT_TAB';
export const SET_ERROR = 'SET_ERROR';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';
export const TOGGLE_SHOW_TUTORIAL = 'TOGGLE_SHOW_TUTORIAL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const RESET_STORE = 'RESET_STORE';

export const togglePreviewMode = createAction(TOGGLE_PREVIEW_MODE);
export const selectTab = createAction(SELECT_TAB);
export const setError = createAction(SET_ERROR);
export const loadUserSuccess = createAction(LOAD_USER_SUCCESS);
export const loadUserFailure = createAction(LOAD_USER_FAILURE);
export const toggleShowTutorial = createAction(TOGGLE_SHOW_TUTORIAL);
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS);
export const updateUserFailure = createAction(UPDATE_USER_FAILURE);
export const resetStore = createAction(RESET_STORE);

export const loadUser = (id) => (
  async (dispatch) => {
    try {
      const user = await visionarService.getUserByWpId(id);
      dispatch(loadUserSuccess(user));
    } catch (error) {
      dispatch(loadUserFailure());
    }
  }
);

export const updateUser = (user) => (
  async (dispatch) => {
    try {
      const updatedUser = await visionarService.updateUser(user);
      dispatch(updateUserSuccess(updatedUser));
    } catch (error) {
      dispatch(updateUserFailure());
    }
  }
);
