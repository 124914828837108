import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_SERVER } from '../config';

export const visionarApi = createApi({
  reducerPath: 'visionarApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_SERVER}/`,
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('access-token');
      if (!token) {
        return headers;
      }

      headers.set('x-access-token', token);
      return headers;
    },
  }),
  tagTypes: ['Projects', 'Me'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query(body) {
        return {
          url: 'users/login',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Me'],
    }),
    register: builder.mutation({
      query(body) {
        return {
          url: 'users/register',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Me'],
    }),
    signInWithGoogle: builder.mutation({
      query(body) {
        return {
          url: 'auth/googleSignIn',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Me'],
    }),
    signUpWithGoogle: builder.mutation({
      query(body) {
        return {
          url: 'auth/googleSignUp',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Me'],
    }),
    acceptPrivacy: builder.mutation({
      query(body) {
        return {
          url: 'me/acceptPrivacy',
          method: 'POST',
          body,
        };
      },
    }),
    requestPasswordReset: builder.mutation({
      query(body) {
        return {
          url: 'passwordReset',
          method: 'POST',
          body,
        };
      },
    }),
    validatePasswordReset: builder.query({
      query: (args) => `passwordReset/${args.userId}/${args.token}`,
    }),
    resetPassword: builder.mutation({
      query(args) {
        return {
          url: `passwordReset/${args.userId}/${args.token}`,
          method: 'POST',
          body: { password: args.password },
        };
      },
    }),
    createStripeCheckoutSession: builder.mutation({
      query(body) {
        return {
          url: 'create-checkout-session',
          method: 'POST',
          body,
        };
      },
    }),
    createStripePortalSession: builder.mutation({
      query(body) {
        return {
          url: 'create-portal-session',
          method: 'POST',
          body,
        };
      },
    }),
    getMe: builder.query({
      query: () => 'me',
      providesTags: () => ['Me'],
    }),
    getUserProjects: builder.query({
      query: () => 'me/projects',
      providesTags: (result) => (result
        ? [
          ...result.map(({ id }) => ({ type: 'Projects', id })),
          { type: 'Projects', id: 'LIST' },
        ]
        : [{ type: 'Projects', id: 'LIST' }]),
    }),
    createProject: builder.mutation({
      query(body) {
        return {
          url: 'projects',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Projects'],
    }),
    getProject: builder.query({
      query: (projectId) => `projects/${projectId}`,
      providesTags: () => ['Projects'],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useSignInWithGoogleMutation,
  useSignUpWithGoogleMutation,
  useAcceptPrivacyMutation,
  useRequestPasswordResetMutation,
  useCreateStripeCheckoutSessionMutation,
  useCreateStripePortalSessionMutation,
  useGetUserProjectsQuery,
  useValidatePasswordResetQuery,
  useResetPasswordMutation,
  useGetMeQuery,
  useCreateProjectMutation,
  useGetProjectQuery,
} = visionarApi;
