import React from 'react';
import StepWizard from 'react-step-wizard';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import backgroundImage from '../../images/background.png';
import SignUpFlowWelcome from '../sign-up-flow/sign-up-flow-welcome';
import SignUpFlowPrivacy from '../sign-up-flow/sign-up-flow-privacy';
import SignUpFlowAccountSetup from '../sign-up-flow/sign-up-flow-account-setup';
import SignUpFlowPricing from '../sign-up-flow/sign-up-flow-pricing';
import visionarLogo from '../../images/visionar_logo_2021_white.svg';
import Copyright from '../copyright';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: 'rgb(28,132,255)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '100vh',
    paddingTop: theme.spacing(10),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    backgroundColor: 'white',
    marginTop: theme.spacing(6),
    padding: theme.spacing(8),
    boxShadow: '0px 4px 24px rgb(0 0 0 / 10%)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: 'auto',
      boxShadow: 'none',
    },
  },
  wizard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: '187px',
    maxWidth: '187px',
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      maxWidth: '150px',
    },
  },
  signInButton: {
    marginLeft: theme.spacing(2),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  footer: {
    position: 'absolute',
    bottom: '0',
    height: '50px',
    maxWidth: '100%',
    color: 'rgba(255, 255, 255, 0.7)',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.text.secondary,
    },
  },
  wrapper: {
    paddingBottom: '100px',
  },
  appBar: {
    boxShadow: 'none',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
}));

const SignUpFlow = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const search = useLocation().search;
  const planParam = new URLSearchParams(search).get('plan');

  const handleLogout = () => {
    sessionStorage.removeItem('access-token');
    history.push('/login');
  };

  const RenderLoginButton = () => {
    if (planParam && planParam.toUpperCase() === 'RETURNING') {
      return (
        <Button
          style={{ fontSize: '0.875rem' }}
          color="inherit"
          className={classes.signInButton}
          onClick={handleLogout}
          size="small"
          variant="outlined"
        >
          SIGN OUT
        </Button>
      );
    }

    if (matches) {
      return (
        <Grid item>
          <Typography variant="body1" display="inline">Already have an account?</Typography>
          <Button
            color="inherit"
            className={classes.signInButton}
            component={RouterLink}
            variant="outlined"
            to="/login"
          >
            SIGN IN
          </Button>
        </Grid>
      );
    }

    return (
      <Button
        color="inherit"
        className={classes.signInButton}
        component={RouterLink}
        size="small"
        variant="outlined"
        to="/login"
      >
        SIGN IN
      </Button>
    );
  };

  const RenderWorkflow = () => {
    if (planParam && planParam.toUpperCase() === 'RETURNING') {
      return (
        <StepWizard isHashEnabled isLazyMount className={classes.wizard}>
          <SignUpFlowWelcome hashKey="welcome" />
          <SignUpFlowPricing hashKey="plans" />
        </StepWizard>
      );
    }

    const planInt = parseInt(planParam, 10);
    if (planInt && (planInt === 1 || planInt === 2 || planInt === 3)) {
      return (
        <StepWizard isHashEnabled isLazyMount className={classes.wizard}>
          <SignUpFlowAccountSetup hashKey="account-setup" />
          <SignUpFlowPrivacy hashKey="privacy" />
        </StepWizard>
      );
    }

    return (
      <StepWizard isHashEnabled isLazyMount className={classes.wizard}>
        <SignUpFlowAccountSetup hashKey="account-setup" />
        <SignUpFlowPrivacy hashKey="privacy" />
        <SignUpFlowPricing hashKey="plans" />
      </StepWizard>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Toolbar>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <img src={visionarLogo} alt="logo" className={classes.logo} />
            </Grid>
            <RenderLoginButton />
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.wrapper}>
        <CssBaseline />
        <RenderWorkflow />
      </div>
      <Container xs={12} className={classes.footer}>
        <Copyright />
      </Container>
    </div>
  );
};

export default SignUpFlow;
