import { connect } from 'react-redux';

import Login from '../components/pages/login';
import { loadUserSuccess } from '../actions/app';

const mapDispatchToProps = (dispatch) => ({
  loadUserSuccess: (user) => { dispatch(loadUserSuccess(user)); },
});

export default connect(null, mapDispatchToProps)(Login);
