import { createAction } from 'redux-actions';
import visionarService from '../services/visionar';

export const CREATE_STRIPE_CHECKOUT_SESSION_REQUEST = 'CREATE_STRIPE_CHECKOUT_SESSION_REQUEST';
export const CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS = 'CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS';
export const CREATE_STRIPE_CHECKOUT_SESSION_FAILURE = 'CREATE_STRIPE_CHECKOUT_SESSION_FAILURE';

export const createStripeCheckoutSessionRequest = createAction(CREATE_STRIPE_CHECKOUT_SESSION_REQUEST);
export const createStripeCheckoutSessionSuccess = createAction(CREATE_STRIPE_CHECKOUT_SESSION_SUCCESS);
export const createStripeCheckoutSessionFailure = createAction(CREATE_STRIPE_CHECKOUT_SESSION_FAILURE);

export const createStripeCheckoutSession = (email, priceId) => (
  async (dispatch) => {
    try {
      dispatch(createStripeCheckoutSessionRequest);
      const sessionId = await visionarService.createStripeCheckoutSession(email, priceId);
      dispatch(createStripeCheckoutSessionSuccess(sessionId));
    } catch (error) {
      dispatch(createStripeCheckoutSessionFailure());
    }
  }
);
