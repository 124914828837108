import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Button from '@mui/material/Button';
import GoogleLogin from 'react-google-login';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { validateEmail } from '../../utils';
import { useRegisterMutation, useSignUpWithGoogleMutation } from '../../services/visionarRTK';
import { GOOGLE_CLIENT_ID } from '../../config';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    marginTop: theme.spacing(6),
    padding: theme.spacing(8),
    boxShadow: '0px 4px 24px rgb(0 0 0 / 10%)',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: 'auto',
      boxShadow: 'none',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  googleButton: {
    width: '100%',
    marginTop: theme.spacing(6),
    height: '50px',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 4px 0px !important',
    fontSize: '1rem !important',
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.50)',
    '&::before': {
      content: '""',
      flex: '1',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    },
    '&::after': {
      content: '""',
      flex: '1',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    },
    '&:not(:empty)::before': {
      marginRight: '.25em',
    },
    '&:not(:empty)::after': {
      marginLeft: '.25em',
    },
  },
  errorMessageHelperText: {
    color: theme.palette.error.main,
  },
}));

const SignUpFlowAccountSetup = ({ nextStep }) => {
  const classes = useStyles();
  const [register, { isLoading }] = useRegisterMutation();
  const [signUpWithGoogle, signUpWithGoogleResult] = useSignUpWithGoogleMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValidation, setEmailValidation] = useState(null);
  const [passwordValidation, setPasswordValidation] = useState(null);
  const [error, setError] = useState(null);

  const handleGoogleSignUp = async (googleData) => {
    try {
      const userData = await signUpWithGoogle({ token: googleData.tokenId }).unwrap();
      sessionStorage.setItem('access-token', userData.token);
      nextStep();
    } catch (err) {
      switch (err.status) {
        case 409:
          setError('email_already_exists');
          break;
        default:
          setError('unknown_error');
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    let errorFound = false;
    if (!email || !validateEmail(email)) {
      setEmailValidation('Please enter a correct email address.');
      errorFound = true;
    }

    if (!password || password.length <= 5) {
      setPasswordValidation('Password must be at least 6 characters long.');
      errorFound = true;
    }

    return !errorFound;
  };

  const onFormSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!validateForm()) return;
      const user = await register({
        email,
        password,
      }).unwrap();
      sessionStorage.setItem('access-token', user.token);
      nextStep();
    } catch (err) {
      switch (err.status) {
        case 409:
          setError('email_already_exists');
          break;
        default:
          setError('unknown_error');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">Set Up Your Account</Typography>
        <GoogleLogin
          className={classes.googleButton}
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Sign up with Google"
          onSuccess={handleGoogleSignUp}
          onFailure={handleGoogleSignUp}
          cookiePolicy="single_host_origin"
        />
        <div className={classes.separator}>or</div>
        <form className={classes.form} noValidate onSubmit={async (e) => onFormSubmit(e)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            error={emailValidation != null}
            helperText={emailValidation}
            name="email"
            autoComplete="email"
            value={email}
            disabled={isLoading || signUpWithGoogle.isLoading}
            onChange={(e) => {
              setEmailValidation(null);
              setEmail(e.target.value);
            }}
            autoFocus
            aria-describedby="email-helper-text"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            value={password}
            error={passwordValidation != null}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            helperText={passwordValidation}
            disabled={isLoading || signUpWithGoogleResult.isLoading}
            onChange={(e) => {
              setPasswordValidation(null);
              setPassword(e.target.value);
            }}
            autoComplete="current-password"
            InputProps={{
              endAdornment:
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      onMouseDown={handleMouseDownPassword}
      size="large">
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>,
            }}
          />
          {
            error
              ? (
                <Alert icon={false} severity="error">
                  {
                    error === 'email_already_exists'
                      ? (
                        <span>
                          An account with this email already exists. Please try a different email
                          address to register, or&nbsp;
                          <RouterLink
                            to="/login"
                          >
                            sign in
                          </RouterLink>
                          {' '}
                          to your existing account.
                        </span>
                      ) : (
                        <span>
                          An authentication exception occurred. Please try again or
                          <a href="mailto:support@visionar.com"> contact support</a>
                          .
                        </span>
                      )
                  }
                </Alert>
              ) : null
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isLoading || signUpWithGoogleResult.isLoading}
            className={classes.button}
          >
            {isLoading || signUpWithGoogleResult.isLoading ? <CircularProgress size={24} /> : <span>Continue</span>}
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default SignUpFlowAccountSetup;

SignUpFlowAccountSetup.propTypes = {
  nextStep: PropTypes.func,
};

SignUpFlowAccountSetup.defaultProps = {
  nextStep: null,
};
