export function isInPreviewMode(state) {
  return state.setting.toJS().previewMode;
}

export function getCurrentUser(state) {
  if (!state.setting) {
    return undefined;
  }

  return state.setting.toJS().user;
}

export function getShowTutorial(state) {
  return state.setting.toJS().showTutorial;
}
