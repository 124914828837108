import React from 'react';

const styles = {
  modalCloseButton: {
    position: 'absolute',
    right: '0',
    height: '25px',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    zIndex: '1000000',
    color: 'white',
  },
};

const DialogCloseButton = ({ handleClick }) => (
  <button onClick={handleClick} style={styles.modalCloseButton}>
    <i className="fa fa-times" aria-hidden="true" />
  </button>
);

export default DialogCloseButton;
