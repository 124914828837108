export function getTimeDiffrence(date) {
  const milliseconds = (date - (new Date()));
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds - (hours * 3600000)) / 60000);
  const seconds = parseInt((milliseconds - (hours * 3600000) - (minutes * 60000)) / 1000, 10);

  return {
    hours, minutes, seconds, milliseconds,
  };
}

export function addHours(date, hours) {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 48);

  return newDate;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}